import * as React from "react";
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import Modal from 'react-bootstrap/Modal'
import UserSession from '../../../services/UserSession';
import NCBIReference from '../../../domain/NCBIReference'
import axios from "axios";
import { UISref, Transition, val} from "@uirouter/react";
import API_URL from '../../../services/Enviroment';
import VCF from "../../../domain/VCF"
import VCFFilesTable from "./VCFFilesTable";
import UserProfileTable from "../UserProfileTable";
import UserGroup from "../../../domain/UserGroup";
import { ProgressBar } from 'react-bootstrap';
import {updateTableRow} from '../../utils/gapCommon';

type VCFFilesState = {
    blocking: boolean,
	blockmessage: string,
   // resultfound: boolean,
    haserror: boolean,
    errors: string,
    hasmodalerror: boolean,
    modalerrors:string;
    showModal: boolean,
    isModalEdit: boolean,
    isModalAdmin: boolean,
    SelelctedVCFFile?: VCF,
    uploadvcfFile?: File,
    progress1: number,
    uservcffiles : Array<VCF>
}

interface UserProps {
    IsAdmin:boolean
}

export default class VCFFiles extends React.Component <UserProps, VCFFilesState> {
    usersession = UserSession.getUserSession();
	NCBIs: { [key: string]: NCBIReference } = {};
	//uservcffiles=new Array();
    allGroups =  Array<UserGroup>();
    selectedGroups=new Array();
    UserType = "User";
    owner =  "system";
    selectedRowid="";

    constructor(props: UserProps) {
        super(props);
       
        this.state = {
            blocking: false,
            blockmessage: "Loading",
            haserror: false,
            errors:"",
            hasmodalerror: false,
            modalerrors:"",
            SelelctedVCFFile: undefined,
            showModal: false,
            isModalEdit: false,
            isModalAdmin: this.props.IsAdmin,
            uploadvcfFile: undefined,
            progress1: 0,
            uservcffiles : []
        };

        this.usersession = JSON.parse(sessionStorage.userSession);
        
        if(props.IsAdmin){
            this.UserType = "Admin";
        }
        else if(this.usersession.email !== null){
            this.owner = this.usersession.email;
        }
    }

	loadReferences = () => {
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            haserror: false,
            errors:"",
            showModal: false
        } as VCFFilesState;
        this.setState(stateJson);

        let url = API_URL +'admin/getAppReferences';
        axios.get(url)
            .then((response) => {
                this.NCBIs = response.data;
                this.loaduserGroup(stateJson);
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                stateJson.blocking = false;
                this.setState(stateJson);
            });
    }

    getuserVCFfiles = (vcffiles : Array<VCF>) => {
        var uservcffiles = Array<VCF>(); 
        if(this.props.IsAdmin){
            uservcffiles = vcffiles as Array<VCF>;
        }
        else{
            var vfiles = new Array<VCF>();
            for(var i = 0; i < vcffiles.length; i++) {
                var vcf = vcffiles[i];
                    if(vcf.owner == this.usersession.email){
                        vfiles.push(vcf);
                    }
            }
            uservcffiles = vfiles;
        }

        return uservcffiles;
    }

    loadvcffiles = (stateJson: VCFFilesState) => {
        this.selectedRowid = "";
        var owner = this.usersession.email;
        if(this.props.IsAdmin){
            owner ='system';
        }
        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, { type: 'vcf', owner: owner, isEdit:true })
            .then(response => {
                var vcffiles = response.data;
                stateJson.uservcffiles = this.getuserVCFfiles(vcffiles);
                stateJson.blocking = false;
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false});
            });
    }

	reloadvcffiles = () => {
        var owner = this.usersession.email;
        if(this.props.IsAdmin){
            owner ='system';
        }
        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, { type: 'vcf', owner: owner, isEdit:true })
            .then(response => {
                var vcffiles = response.data;
                var uservcffiles = this.getuserVCFfiles(vcffiles);

                // var index = uservcffiles.findIndex((e) => e.vcfid === row.vcfid);
                // var oldrowdata = vcffiles[index];
                // var newrowdata = JSON.parse(JSON.stringify(oldrowdata)) as VCF;
                // newrowdata[colname]= row[colname];
                // var newresult = updateTableRow(vcffiles, index, newrowdata);
                this.setState({uservcffiles: uservcffiles});

            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false});
            });
    }

    loaduserGroup = (stateJson: VCFFilesState) => {
        let url = API_URL + 'user/getUserGroups';
        axios.post(url, { isadmin: this.state.isModalAdmin, email: this.owner })
            .then((response) => {
                this.allGroups = response.data;
                this.loadvcffiles(stateJson);
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                this.setState({blocking: false});
            });
    }

    updateUserGroupData = (row: VCF) => {
        this.selectedGroups = [];
        var groupids = new Array();
        if (row.groupIdStr !== undefined) {
            groupids = row.groupIdStr.split(";");
        }

        for (var i = 0; i < this.allGroups.length; i++) {
            var group = this.allGroups[i];
            if (groupids.includes(group.groupid)) {
                this.selectedGroups.push(group.groupid);
            }
        }
    }

    onDeleteVCF = (row: VCF) => {
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            haserror: false,
            errors:"",
            showModal: false,
            //uservcffiles:new Array<VCF>()
        } as VCFFilesState;
        this.setState(stateJson);

        let url = API_URL + 'genomics/deleteVCFfile';
        axios.post(url, { vcffile: row })
            .then(response => {
                stateJson.haserror = true;
                stateJson.errors = response.data;
                this.loadvcffiles(stateJson);
            })
            .catch(error => {
                var errormessage = error.response.data;

                stateJson.haserror = true;
                stateJson.errors = error.response.data;
                stateJson.blocking = false;
                this.selectedRowid = row.vcfid;
                stateJson.blocking = false;
                this.setState(stateJson);
            });
    }

    onAddnewVCF =() =>{
        var allrefs = this.NCBIs;
        var refer_names = Object.keys(this.NCBIs);
        var default_refer = refer_names.filter(function (ref) {
          return allrefs[ref].isdefault;
        });

        var newVCF = {reference:  default_refer[0]} as VCF;
        this.updateUserGroupData(newVCF);
        this.setState({
            isModalEdit: false,
            showModal: true,
            SelelctedVCFFile: newVCF,
            progress1:0
        });
    }

    onUpdateVCF = (row : VCF) =>{
        let url = API_URL + 'genomics/updateVCF';
        axios.post(url, { vcffile: row })
            .then(response => {
                var vcffiles = this.state.uservcffiles;
                var index = vcffiles.findIndex((e) => e.vcfid === row.vcfid);
                var oldrowdata = vcffiles[index];
                var newrowdata = JSON.parse(JSON.stringify(oldrowdata)) as VCF;
                newrowdata.reference= row.reference;
                var newresult = updateTableRow(vcffiles, index, newrowdata);
                this.setState({uservcffiles: newresult});
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false, errors: error.message });
            });
    }

    onUpdateRowData = (row : VCF) =>{
        let url = API_URL + 'genomics/updateVCF';
        axios.post(url, { vcffile: row })
            .then(response => {
                this.selectedRowid = row.vcfid;
                this.reloadvcffiles();
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false, haserror:false, errors: error.message });
            });
    }

    onEditVCF = (row: VCF) => {
        this.updateUserGroupData(row);
        this.selectedRowid = row.vcfid;
        this.setState({
            isModalEdit: true,
            showModal: true,
            SelelctedVCFFile: row
        });
    }

    //Modal
    onClose =() =>{
        var stateJson = {
            showModal: false,
            blocking : true,
         //   resultfound: false
        } as VCFFilesState;
        this.setState (stateJson);
        this.loadvcffiles(stateJson);
    }

    onEnterDesc = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var vcf = this.state.SelelctedVCFFile;
        if(vcf != undefined){
            vcf.vcf_description = target.value;
            this.setState ({SelelctedVCFFile:  vcf});
        }
    }

    OnSelectedRef = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;

        var vcf = this.state.SelelctedVCFFile;
        if(vcf != undefined){
            vcf.reference = target.value;
            this.setState ({SelelctedVCFFile:  vcf});
        }
    };

    OnhandleselectRows =(selectedRows:[]) =>{
        this.selectedGroups = selectedRows;
    }

    onVcffileChange = (event: React.ChangeEvent <HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        var filename = fileList[0].name;
        var filenames = filename.split(' ');
        var valid = true as Boolean;
        for(let i=0; i< filenames.length; i++){
            var f = filenames[i];
            if (f.startsWith("-")){
                valid = false;
            }
        }
        if(valid){
            this.setState({uploadvcfFile: fileList[0], modalerrors: "", hasmodalerror:false})
        }
        else{
            this.setState({uploadvcfFile: undefined, modalerrors: "Invalid File Name", hasmodalerror:true})
        }
    }

    saveUser = () => {
        var selectedGroupIds: { [key: string]: boolean } = {};

        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            haserror:false,
            errors:"",
            showModal: false
        } as VCFFilesState;
        this.setState(stateJson);
        
        this.selectedGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        let url = API_URL + 'user/savevcfgroup';
        axios.post(url, { vcfid: this.state.SelelctedVCFFile?.vcfid, groupsIds: selectedGroupIds })
            .then(response => {
                if (response.status == 200) {
                    let url = API_URL + 'genomics/updateVCF';
                    axios.post(url, { vcffile: this.state.SelelctedVCFFile })
                        .then(response => {
                            this.loadvcffiles(stateJson);
                        })
                        .catch(error => {
                            console.error('There was an error!' + error.message);
                            this.setState({ blocking: false });
                        });
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false });
            });
    }

    onUploadVCF =() =>{
        var selectedGroupIds: { [key: string]: boolean } = {};
        this.selectedGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        })

        if(!this.state.uploadvcfFile?.name.endsWith(".vcf") && !this.state.uploadvcfFile?.name.endsWith(".vcf.gz")) {
            alert("Please select .vcf or .vcf.gz file to upload.");
            return;
        }

        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            haserror:false,
            errors:"",
        } as VCFFilesState;
        this.setState(stateJson);

        let url = API_URL + 'genomics/uploadVCF';
        let formData = new FormData()
        formData.append("file", this.state.uploadvcfFile);
        formData.append("adminvcf", this.state.isModalAdmin.toString());
        formData.append("filename", this.state.uploadvcfFile.name);
        if(this.state.SelelctedVCFFile !== undefined){
            formData.append("vcfdescription", this.state.SelelctedVCFFile.vcf_description? this.state.SelelctedVCFFile.vcf_description : "");
            formData.append("reference", this.state.SelelctedVCFFile?.reference);
        }
        formData.append("groupsIds", JSON.stringify(selectedGroupIds));
        axios.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
              this.setState({ progress1: (Math.round((100 * data.loaded) / data.total)) });
            },
        })
        .then(response => {
            stateJson.showModal=false;
            this.loadvcffiles(stateJson);
        })
        .catch(error => {
              console.error('There was an error!' + error.message);
              this.setState({ errors: 'There was an error!' + error.message });
        });
    }

    componentDidMount(){	
		this.loadReferences();
	}

    render() {
		this.usersession = JSON.parse(sessionStorage.userSession);
        
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
                    <div className="gap-page-content">
                        <div className="container">
                            <h1>{this.UserType} VCF Management</h1>
                            <form name="vcfForm">
                                <div className="row margin-bottom-10">
                                    <div className="button-right" >
                                        <button id="addnewvcf" type="button" className="btn btn-primary" onClick={this.onAddnewVCF}>Add new VCF</button>
                                    </div>
                                </div>

                                {this.state.haserror &&
                                    <div className="row redcolor">
                                        <pre>{this.state.errors}</pre>
                                    </div>
							    }

                                {//this.state.resultfound &&
                                    <div className="row">
                                        <VCFFilesTable
                                            searchResults={this.state.uservcffiles}
                                            isAdmin={!this.props.IsAdmin}
                                            referenceData={this.NCBIs}
                                            onEditVCF={(row: VCF) => this.onEditVCF(row)}
                                            onDeleteVCF={(row: VCF) => this.onDeleteVCF(row)}
                                            onUpdateRowData={(row: VCF) => this.onUpdateRowData(row)}
                                            selectedrowid={this.selectedRowid}
                                        />
                                    </div>
                                }
                            </form>
                            <Modal show={this.state.showModal}
                                size="lg"
                                animation={false}
                                backdrop="static"
                                keyboard={false}>
                                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            {this.state.isModalEdit &&
                                                <div>
                                                    <h1 className="lead intro">Edit VCF file </h1>
                                                    <hr className="modal-separator"></hr>
                                                    <div className="row">
                                                        <div>
                                                            <label className="modal-lable">VCF File:  {this.state.SelelctedVCFFile?.displayname}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {!this.state.isModalEdit &&
                                                <div>
                                                    <h1 className="lead intro">Add New VCF file</h1>
                                                </div>
                                            }
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    {this.state.hasmodalerror &&
                                        <div className="row redcolor">
                                            <pre>{this.state.modalerrors}</pre>
                                        </div>
							        }

                                        {!this.state.isModalEdit &&
                                            <div>
                                                <div className="row">
                                                    <div className="form-group" >
                                                        <label htmlFor="vcffile">Upload VCF File (containing identifiers in cases and control files)</label>
                                                        <div>
                                                            <input type="file" id="vcffile" onChange={this.onVcffileChange} accept=".vcf,.gz" />
                                                            <p></p>
                                                            <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row col-lg-12">
                                            <div className="form-group margin-top-10" >
                                                <label htmlFor="description">VCF Description</label>
                                                <input type="text" className="form-control" id="description" value={this.state.SelelctedVCFFile?.vcf_description}
                                                    placeholder="input VCF Description" onChange={this.onEnterDesc}
                                                    width="60px" />
                                            </div>
                                            <div className="form-group margin-top-10">
                                                <label htmlFor="ncbis">Reference genomes</label>
                                                <select
                                                    className="form-select"
                                                    value={this.state.SelelctedVCFFile?.reference}
                                                    onChange={this.OnSelectedRef}>
                                                    {Object.entries(this.NCBIs).map(([key, value], i) => (
                                                        <option key={key} value={value.referenceName}>{value.referenceName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row col-lg-12" >
                                            <div className="margin-top-10">
                                                <label>Select User Groups:</label>
                                            </div>
                                        </div>
                                        <div className="row margin-right-5">
                                            <UserProfileTable
                                                searchResults={this.allGroups}
                                                IsVCFGroup={true}
                                                SelectedRow={this.selectedGroups}
                                                IsGroupTable={true}
                                                IsSelectTable={true}
                                                HideSelectColumn={false}
                                                OnSelect={(selectedRows: []) => this.OnhandleselectRows(selectedRows)}>
                                            </UserProfileTable>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div>
                                            {!this.state.isModalEdit &&
                                                <button type="button" className="btn btn-primary" disabled={!this.state.uploadvcfFile} onClick={this.onUploadVCF} >Upload</button>
                                            }
                                            {this.state.isModalEdit &&
                                                <button type="button" className="btn btn-primary" onClick={this.saveUser} >Save</button>
                                            }
                                            <button className="btn btn-outline-secondary" onClick={this.onClose}> Close </button>
                                        </div>

                                    </Modal.Footer>
                                </BlockUi>
                            </Modal>
                        </div>
                    </div>
                </BlockUi>
            </div>
        );
    }
  }
