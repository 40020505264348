import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Transition } from '@uirouter/react';
import NcbiSViewer from './NcbiSViewer';
import NCBIReference from '../../domain/NCBIReference'
import axios from "axios";
import NCBITrackData from "../../domain/NCBITrackData";
import API_URL from '../../services/Enviroment';

type ReferenceState = {
  selectedRef: string,
  trackurls: string,
  //selectedTrack: string,
  reloadSview: number
}

export default class GenomeBrowser extends React.Component <Transition, ReferenceState> {
  referenceData: { [key: string]: NCBIReference } = {};
  sviewlink = "";
  //trackDatas = new Array<NCBITrackData>();

  constructor(props: Transition) {
    super(props);

    this.state = {
      selectedRef: "",
      trackurls:"",
      //selectedTrack: "",
      reloadSview: 1
    };

    if (sessionStorage.token == null || sessionStorage.token == "") {
      let transition = props;
      const $state = transition.router.stateService;
      $state.go('home');
    }
  }

  UNSAFE_componentWillMount(){
    this.loadReferences();
  }
 
  loadReferences = () => {
    let url = API_URL +'admin/getAppReferences';
    axios.get(url)
      .then(response => {
        this.referenceData = response.data;
        //find default ref
        let allRefs =  this.referenceData;
        var default_ref = "NC_000962.3";
        var refer_names = Object.keys(allRefs);
        var default_refs = refer_names.filter(function (ref) {
          return allRefs[ref].isdefault;
        });

        if (default_refs.length > 0) default_ref = default_refs[0];
        this.sviewlink = "?" + this.referenceData[default_ref].NCBI_Embedded;
        var selectedref = this.referenceData[default_ref].NCBI_Embedded;
        //this.trackDatas = this.referenceData[default_ref].trackdatas;
        var truckurls = this.setupTrackURL(this.referenceData[default_ref].trackdatas);
        var reloadSview = this.state.reloadSview +1;
        this.setState ({selectedRef: selectedref, reloadSview: reloadSview, trackurls: truckurls});
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
      });
  }

  onReferenceChange = (event: React.FormEvent<HTMLSelectElement>) => {
    var target = event.target as HTMLSelectElement;
    this.sviewlink = "?" + target.value;
    var selectedindex = target.options.selectedIndex;

    var selectedref = target.options[selectedindex];
    var tackdatas = this.referenceData[selectedref.text].trackdatas;

    var tranckurls= this.setupTrackURL(tackdatas);

    var reloadSview = this.state.reloadSview +1;
    this.setState ({selectedRef: target.value, reloadSview: reloadSview, trackurls: tranckurls});
  };

  setupTrackURL =(tackdatas: Array<NCBITrackData>)=>{
    var tranckurls="";
    for (let i = 0; i < tackdatas.length; i++) {
      var trackdata =   tackdatas[i];
      if(trackdata.ispublic){
        if(tranckurls == ""){
          tranckurls = "url=" + trackdata.trackdataurl;
        }
        else{
          tranckurls = tranckurls + "&url=" + trackdata.trackdataurl;
        }
      }
    }

    return tranckurls;
  }

  render(){
    return (
      <div className="gap-page-content">
        <div className="container">
          <h1>NCBI TB Genome Browser</h1>
          <p>Use the NCBI Sequence Viewer to browse the TB Genome.  The NCBI
            Sequence Viewer has a rich set of features described in the <a href="https://www.ncbi.nlm.nih.gov/tools/sviewer/" target="_blank">user guide. </a>
          </p>
          <hr />
          <div className="row">
            <div className="col-md-3">
              <label>Reference genomes</label>
              <select
                className="form-select"
                value={this.state.selectedRef}
                onChange={this.onReferenceChange}>
                {Object.entries(this.referenceData).map(([key, value], i) => (
                  <option key={key} value={value.NCBI_Embedded}>{value.referenceName}</option>
                ))}
              </select>                     
            </div>
           
          </div>
          <br />
          <NcbiSViewer NCBIEmbeddedParameter={this.state.selectedRef} reloadSview={this.state.reloadSview} trackurl={this.state.trackurls} position='' />
        </div>
      </div>
    );
  }
}