import React from "react";
import './Home.css'
import { UISref, UISrefActive, Transition} from "@uirouter/react";
import plinkIcon from '../assets/images/plink.svg';
import resistanceIcon from '../assets/images/resistance.svg';
import genomicsIcon from '../assets/images/genomics.svg';
import API_URL from "../services/Enviroment";
import {removeOrcidCode} from './utils/gapCommon';

export default class Home extends React.Component<Transition> {
	constructor(props: Transition) {
		super(props);

    console.log("API_URL:" + API_URL);
  }
 
  render() {
    removeOrcidCode("home", this.props);
    return (
      <div className="home-page-content">
        <div className="container">
          <div className="starter-template">
            <h1 className="gap-hero-title">Genomic Analysis Tool for TB Portals’ Data</h1>
            <p className="lead gap-hero-subtitle">The Genomic Analysis Portal (GAP) facilitates the in-depth analysis of genomic data from the TB Portals project as well as data from other TB sequencing projects.</p>
          </div>

          <ul className="row list-unstyled" >
            <li className="col-xs-12 col-sm-4 col-md-4">
              <UISrefActive class="active">
                <UISref className="img-thumbnail gap-thumbnail" to="variants">
                  <a>
                    <div className="caption gap-apps-inner">
                      <p className="gap-app-names">Variants</p>
                      <p className="gap-app-desc">Explore and analyze genomic variants</p>
                      <p className="gap-app-icon">
                        <img className="individual-app-icons" src={plinkIcon} alt="variants" />
                      </p>
                    </div>
                  </a>
                </UISref>
              </UISrefActive>
            </li>

            <li className="col-xs-12 col-sm-4 col-md-4">
              <UISrefActive class="active">
                <UISref className="img-thumbnail gap-thumbnail" to="resistance">
                  <a>
                    <div className="caption gap-apps-inner">
                      <p className="gap-app-names">Resistance Prediction</p>
                      <p className="gap-app-desc">Predict resistance to TB drugs</p>
                      <p className="gap-app-icon"><img className="individual-app-icons" src={resistanceIcon} alt="Resistance" /></p>
                    </div>
                  </a>
                </UISref>
              </UISrefActive>
            </li>

            <li className="col-xs-12 col-sm-4 col-md-4">
              <UISrefActive class="active">
                <UISref className="img-thumbnail gap-thumbnail" to="genomeBrowser">
                  <a>
                    <div className="caption gap-apps-inner">
                      <p className="gap-app-names">Genome Browser</p>
                      <p className="gap-app-desc">Browse the TB genome</p>
                      <p className="gap-app-icon"><img className="individual-app-icons" src={genomicsIcon} alt="ncbiSViewer" /></p>
                    </div>
                  </a>
                </UISref>
              </UISrefActive>
            </li>
          </ul>
        </div>
      </div>
    )
  }
};
