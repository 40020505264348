import React, { ComponentType } from "react";
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal'
import {  UISref, UISrefActive, UIRouterGlobals } from "@uirouter/react";
import UserSession from '../../services/UserSession'
import axios from "axios";
import validator from 'validator'
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import 'react-block-ui/style.css';
import queryString from 'query-string';
import niaidlogowhite from '../../assets/images/niaid-logo-white.svg';
import './Header.css'
import API_URL from '../../services/Enviroment';

type SignInState = {
    show: boolean,
	accountexisting: boolean,
	validEmail: boolean,
	displayverifycode: boolean,
	displayOrcidLogin: boolean,
	email: string,
	code: string,
	registeruser: boolean,
	orcidlogin: boolean,
	registeremaildisabled:boolean,
	blocking:boolean,
	verifycodeBt:string,
	firstName:string,
	lastName:string,
	organization:string,
	registeremail:string,
	registerorcid:string,
	orcid_access:string,
	orcid_code:string,
	errors: string | null
}

interface UserProps {
    show:boolean,
	blocking:boolean,
	targetstate: string,
	loginmessage: string,
	closeHandle(islogin:boolean): void
}

export default class SignIn extends React.Component <UserProps, SignInState> {
	
	usersession = UserSession.getUserSession();

	constructor(props: UserProps) {
		super(props);
	
		this.state = {
			errors: "",
			email: "",
			code: "",
			displayverifycode: false,
			accountexisting: false,
			validEmail: false,
			registeruser: false,
			orcidlogin: false,
			displayOrcidLogin: true,
			blocking: false,
			registeremaildisabled: false,
			verifycodeBt: 'Verify Code',
			firstName: "",
			lastName: "",
			organization: "",
			registeremail: "",
			registerorcid: "",
			orcid_access: "",
			orcid_code: "",
			show: false
		};
	}

	handleClose = () => {
		this.props.closeHandle(false);
	}

	verifycode=() => {
		this.setState({ blocking: true, errors:"" });
		let url = API_URL +'admin/verifyCode';
        axios.post(url, {"email": this.state.email, "code": this.state.code})
			.then(response => {
				if(response.data.isverified){
					this.usersession = response.data;
					sessionStorage.setItem("userSession", JSON.stringify(response.data));
					this.setState({show: false, blocking: false});
					this.props.closeHandle(true);
				}
				else{
					this.setState({ blocking: false, errors:  this.usersession.verifiedmessage});
				}
			})
			.catch(error => {
				console.error('There was an error!' + error.message);
				this.setState({ blocking: false, errors:  error.message});
			});
	};

	loginORCID = () => {
		this.setState({ orcidlogin: true });
		if(this.props.targetstate === ""){
			sessionStorage.setItem("orcidLoginOrginState", location.pathname);
		}
		else{
			sessionStorage.setItem("orcidLoginOrginState", "/" + this.props.targetstate);
		}

		let url = API_URL + 'admin/orcidauthorizeRequest';
		axios.get(url, { params: { isuserlist: true } })
			.then(response => {
				var orcidreq = response.data.request;
				window.open(orcidreq, '_self');
			})
			.catch(error => {
				console.error('There was an error!' + error.message);
				this.setState({ errors: error.message });
			});
	}

	sendPin =() => {
		this.setState({ blocking: true, errors:"" });

		let url = API_URL +'admin/sendCode';
        axios.post(url, {"email": this.state.email})
			.then(response => {
				var statusJson = response.data;
				var status = statusJson.status;
				var displayverifycode = false;
				
				if(status == "RequestFailed"){
					this.setState({
						registeruser:true,
						blocking: false,
						registeremaildisabled:true,
						displayverifycode: false,
					});
				}
				else{
					this.setState({blocking: false, displayverifycode: true});
				}	
			})
			.catch(error => {
				console.error('There was an error!' + error.message);
				this.setState({ blocking: false, errors:  error.message});
			});
	}

	onEnterEmail = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;

		if (validator.isEmail(target.value)) {
			this.setState({ email: target.value, validEmail: true });
		} else {
			this.setState({ email: target.value, validEmail: false });
		}
	}

	onEnterCode = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		this.setState({ code: target.value});
	}

	
	onEnterFirstName =(event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		this.setState({ firstName: target.value});
	}

	onEnterLastName = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		this.setState({ lastName: target.value});
	}

	onOrganization = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		this.setState({ organization: target.value});
	}

	onRegisterEmail = (event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
		this.setState({ registeremail: target.value});
	}

	registernewuser = () => {
		this.setState({ blocking: true, errors: "" })
		var registerUser = {
			"email": this.state.registeremail,
			"lastname": this.state.lastName,
			"firstname": this.state.firstName,
			"organization": this.state.organization,
			"orcid": this.state.registerorcid,
		}
		let url = API_URL + 'admin/registerUser';
		axios.post(url, registerUser)
			.then(response => {
				if (this.state.orcidlogin == true) {
					var error = response.data;
					if (error.startsWith("A user already exists")) {
						this.setState({
							blocking: false,
							accountexisting: true,
							verifycodeBt: "Merge Accounts",
							registeruser: false,
							displayverifycode: true,
							email: this.state.registeremail,
							displayOrcidLogin: true
						})
					}
					else {
						this.setState({
							blocking: false,
							accountexisting: false,
							registeruser: false,
							displayverifycode: true,
							email: this.state.registeremail,
							displayOrcidLogin: false
						})
					}
				}
				else {
					this.setState({
						blocking: false,
						accountexisting: false,
						registeruser: false,
						displayverifycode: true,
						email: this.state.registeremail,
						displayOrcidLogin: false
					})
				}
			})
			.catch(error => {
				console.error('There was an error!' + error.message);
				this.setState({ blocking: false, errors: error.response.data });
			});
	}

	render(){
		this.usersession = JSON.parse(sessionStorage.userSession);
		return (
			this.props.show && 
			 <div className="Header-background">
				
				<Modal
					show={true}
					animation={false}
					onHide={this.handleClose}
					backdrop="static"
					keyboard={false}>
					<Modal.Header closeButton>
						<Modal.Title><h5>REQUEST IDENTIFICATION CODE</h5></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<BlockUi tag="div" blocking={this.state.blocking || this.props.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
							{!this.state.registeruser &&
								<div>
									<div className="row" >
										<div className="col-lg-12">
											<div className="panel panel-default">
												<div>
													<form name="loginform" className="form-inline gap-pin-code">
														{this.state.accountexisting &&
															<div className="margin-10">
																<div className="form-group text-align-left">
																	A user already exists with an email address of <span className="redcolor"></span>, We sent an identification code to the email, Please verify and merge account.
																</div>
															</div>
														}
														{this.props.loginmessage !== "" && 
															<div className="form-group text-align-left">
																{this.props.loginmessage}
															</div>
														}

														<div className="row g-3 align-items-center margin-top-10">
															<div className="col-auto">
																<label className="lcol-form-label">Email address:</label>
															</div>
															<div className="col-auto">
																<input type="email" className="form-control" name="email"
																	id="email" placeholder="you@email.com" required disabled={this.state.displayverifycode}
																	value={this.state.email} onChange={this.onEnterEmail} />
															</div>
															<div className="col-auto">
																<button type="button" className="btn btn-outline-secondary" disabled={!this.state.validEmail} style={{ width: '125px' }}
																	onClick={this.sendPin} >Get Pin Code</button>
															</div>
														</div>
														{this.state.displayverifycode &&
															<div className="row g-3 align-items-center margin-top-10">
																<div className="col-auto">
																	<label className="lcol-form-label label_right">Pin Code:</label>
																</div>
																<div className="col-auto">
																	<input type="text" className="form-control" id="verifycode" name="verifycode" required
																		value={this.state.code} placeholder="code" onChange={this.onEnterCode} />
																</div>
																<div className="col-auto">
																	<button type="button" className="btn btn-primary" disabled={!this.state.code} style={{ width: '125px' }}
																		onClick={this.verifycode}>{this.state.verifycodeBt}</button>
																</div>
															</div>
														}
														{this.state.validEmail && !this.state.displayverifycode &&
															<div className="float_left_margin-left-20  margin-top-20">
																<a style={{ color: "#0d6efd" }} onClick={() => this.setState({ displayverifycode: true })} >I already have a code.</a>
															</div>
														}

														{this.state.displayOrcidLogin &&
															<div>
																<br />
																<div>
																	<p className="hr-text-container">
																		<span className="hr-text-content">or</span>
																	</p>
																	<br />
																	<div>
																		<button type="button" className="btn btn-outline-secondary" onClick={this.loginORCID}>
																			<img src={require('../../assets/images/orcid.favicon.ico')}></img>
																			&nbsp;&nbsp;Log In with ORCiD
																		</button>
																	</div>
																	<br />
																</div>
															</div>
														}
														{this.state.errors != null &&
															<div className="row redcolor">
																<p>{parse(this.state.errors)}</p>
															</div>
														}
													</form>
												</div>
											</div>
										</div>
									</div>
									{this.state.displayverifycode &&
										<div className="row" >
											<div className="col-lg-12">
												<div>
													<a className="link-primary" onClick={() => this.sendPin()} >I have not received my code, please send again.</a>
												</div>
											</div>
										</div>
									}
									{!this.state.displayverifycode &&
										<div className="row">
											<div className="col-lg-12">
												Not Registered Yet?
												<br />
												You will need to <a className="link-primary" onClick={() => { this.setState({ registeruser: true, registeremaildisabled: false }) }} >register</a> and log in when you want to save a cohort.
											</div>
										</div>
									}
								</div>
							}
							{this.state.registeruser &&
								<div className="row" >
									 {this.state.errors != null &&
										<div className="row redcolor">
											<p>{parse(this.state.errors)}</p>
										</div>
									}
									<div className="row col-lg-12">
										<div className="panel panel-default">
											<form name="registform" className="margin-10">
												<div className="form-group" >
													<label htmlFor="firstname" className="nameLabelCss">First Name</label>
													<input type="text" className="form-control" name="firstname" value={this.state.firstName}
														id="firstname" placeholder="Enter your first name" onChange={this.onEnterFirstName} />
												</div>
												<div className="form-group">
													<label htmlFor="lastname">Last Name</label>
													<input type="text" className="form-control" name="lastname" value={this.state.lastName}
														id="lastname" placeholder="Enter your last name" onChange={this.onEnterLastName} />
												</div>
												<div className="form-group" >
													<label htmlFor="organization">Organization Name</label>
													<input type="text" className="form-control" name="organization" value={this.state.organization}
														id="organization" placeholder="Enter your organization name" onChange={this.onOrganization} />
												</div>
												<div className="form-group">
													<label htmlFor="email">Email address</label>
													<input type="email" className="form-control" name="registeremail" value={this.state.registeremail}
														id="registeremail" placeholder="Enter your email address" onChange={this.onRegisterEmail}
														disabled={this.state.registeremaildisabled} />
												</div>
												{this.state.orcidlogin &&
													<div>
														<div>
															<span className='redcolor'>Please use the same email address to register your ORCID.</span>
														</div>
														<div className="form-group">
															<label htmlFor="orcidid">ORCID ID</label>
															<input type="text" className="form-control" name="registerorcid" value={this.state.registerorcid}
																id="registerorcid" disabled={true} />
														</div>
													</div>
												}
												<div className="row margin-right-5 margin-top-20">
													<div className="form-group floatright">
														<button type="button" className="btn btn-primary" onClick={this.registernewuser}
															disabled={!this.state.registeremail || !this.state.firstName || !this.state.lastName || !this.state.organization}
														>Register</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							}
						</BlockUi>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Modal>
			</div>
		);
	};
};