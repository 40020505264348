import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as BSIcon from "react-icons/bs";
import {calculateTablePage} from '../../../utils/gapCommon';
import "./reference.css";

var selectedpage = 1;
var selectedrowid = [];

export default class TrackDataTable extends React.Component {
  selectedRows = [];

  constructor(props) {
    super(props);
    console.log(props);
  }

  //Define Columns for Group, User and List
  TrackColumns = [
    { dataField: 'tracktype', text: 'Track Type', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'130px'},
      headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    },
    { dataField: 'trackname', text: 'Track Name', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'200px'},
      headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    },
    { dataField: 'trackdataurl', text: 'Track Data Location', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'400px'},
      headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    },
    { 
      dataField: 'description', text: 'Description', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'400px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    }, 
    { 
      dataField: 'ispublic', text: 'Publish', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      formatter: (cellContent, row) => {
        return (
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={ row.ispublic } onChange={event => this.onPublicChange(event, row)} />
            </label>
          </div>
        )
      }
    }, 
    { 
        dataField: '', text: '', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'130px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.IsSelectTable,
        formatter: (cell, row) => {
          return <div className="tablecellcenter">
            <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditTrackData(row)}>
              <BSIcon.BsPencil className="margin-bottom-3" title="Edit Track Data" />
            </button>
            <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onViewTrackData(row)}>
              <BSIcon.BsViewStacked className="margin-bottom-3" title="View Track" />
            </button>
            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onDeleteTrackData(row)}>
              <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete Track Data" />
            </button>
          </div>;
        }
    }
  ];

  onDeleteTrackData =(row)=>{
    var r = window.confirm("Do you want to delete Track Data: " + row.trackname);
		if (r === false) {
		    return;
		}

    this.props.onDeletetrackData(row);
  }

  onViewTrackData=(row)=>{
      this.props.onViewTrackData(row);
  }

  onPublicChange =(event, row) => {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.ispublic = new_value;
    this.props.onUpdateRowData(newRow);
  }

  onEditTrackData=(row) =>{
    this.props.onEditTrackData(row);
  }

  render(){
    
    [selectedpage, selectedrowid] = calculateTablePage (this.node, this.props.selectedrowid, "trackId");

    const trackdataPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.trackdatas.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { backgroundColor: "#c8e6c9" },
      selected: selectedrowid,
    };
    
    return (
      <div>
        <PaginationProvider pagination={paginationFactory(trackdataPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField="trackId"
                    ref={ n => this.node = n }
                    data={this.props.trackdatas}
                    columns={this.TrackColumns}
                    selectRow={ selectedRow }
                    {...paginationTableProps}
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
        
      </div>
    );
  }
};