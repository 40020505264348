/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React from "react";

function NcbiSViewer(props) {
  const sviewerscript = document.getElementById('sviewerscript');
  if(sviewerscript){
    sviewerscript.parentNode?.removeChild(sviewerscript);
  }

  var s = document.createElement('script');
    s.setAttribute('src', 'https://www.ncbi.nlm.nih.gov/projects/sviewer/js/sviewer.js');
    s.id = 'sviewerscript';
    document.body.appendChild(s);

  const buildsview = function () {
    var href = props.NCBIEmbeddedParameter;
    if(props.trackurl !== ""){
      var trackurl = props.trackurl;
      if(trackurl.toLowerCase().startsWith("http")){
        trackurl = "url=" + trackurl;
      }
      href = href + "&" + trackurl;
    }
    var html = '<div id="rzrgx4" class="SeqViewerApp" data-autoload> <a href="?' + encodeURIComponent(href) + '"></a></div>' 
    
    var element = document.getElementById("sview");
    if (element) {
      element.parentNode?.removeChild(element);
    }

    var newElement = document.createElement('div');
    newElement.setAttribute('id', "sview");
    newElement.innerHTML = decodeURIComponent(html);

    var p = document.getElementById("sview_main");
    p?.appendChild(newElement);

    if (typeof SeqView != "undefined") {
      SeqView = null;
    }
  }

  React.useEffect(() => {
    if(props.position !== ""){
      SeqView.m_Apps[0].parseAndGotoPosition(props.position, {range_only: false});
    }
    else{
      if (props.NCBIEmbeddedParameter != null && props.NCBIEmbeddedParameter !== '') {
        buildsview();
      }
    }
  }, [props.reloadSview]);

  return (
    <div>
      {props.NCBIEmbeddedParameter !== '' &&
        <div id="sview_main" className="row">
        </div>
      }
    </div>
  )
};

export default NcbiSViewer;