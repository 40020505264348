import * as React from "react";
import { UISref, Transition} from "@uirouter/react";
import VCFFile from "./VCFFiles"


export default class UserVCFFiles extends React.Component <Transition> {
    
    constructor(props: Transition) {
        super(props);
    }

    render() {
        var usersession = JSON.parse(sessionStorage.userSession);
        if(!usersession.isverified){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }
        return (
            <VCFFile IsAdmin={false} ></VCFFile>
        );
    }
  }
