
import React from "react";
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import { UISref, Transition} from "@uirouter/react";
import Select, {SingleValue, createFilter} from 'react-select';
import API_URL from '../../../services/Enviroment';
import UserSession from '../../../services/UserSession';
import UserGroup from "../../../domain/UserGroup";
import UserGroupTable from '../UserProfileTable';
import User from "../../../domain/User";
import "./UserAdmin.css";

type userState = {
    blocking: boolean,
    blockmessage: string,
    resultfound: boolean,
    SelelctedGroup?: UserGroup,
    SelelctedUser? : User,
    showGroupModal: boolean,
    showUserModal: boolean,
    isModalEdit: boolean,
    errors: string
}

export default class UserAdmin extends React.Component <Transition, userState> {
    usersession = UserSession.getUserSession();
    allGroups =  Array<UserGroup>();
    allUsers =  Array<User>();
    isAdminGroup = false;
    selectedUsers=new Array();
    selectedGroups=new Array();

    constructor(props: Transition) {
        super(props);
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isadminuser){
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.state = {
            blocking: false,
            blockmessage: "Loading",
            resultfound: false,
            SelelctedGroup: undefined,
            SelelctedUser: undefined,
            isModalEdit: false,
            showGroupModal: false,
            showUserModal: false,
            errors: ""
        };
    }

    loaduserdata =(stateJson: userState) =>{
        let url = API_URL +'user/getallusers';
        axios.get(url)
            .then((response) => {
                if(response.status == 200 ){
                    this.allUsers = response.data.Users;
                    this.allGroups = response.data.Groups;

                    stateJson.blocking = false;
                    stateJson.resultfound = true;
                    this.setState(stateJson);
                }
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                stateJson.blocking = false;
                this.setState(stateJson);
            });
  }

    onDeleteGroup = (row: UserGroup) => {
        var stateJson = {
            blocking: true,
            blockmessage: "Loading ... ",
            resultfound: false,
            errors: "",
            showGroupModal: false,
            showUserModal: false
        } as userState;
        this.setState(stateJson);

        let url = API_URL + 'user/deletegroup';
        axios.post(url, { groupid: row.groupid })
            .then(response => {
                this.loaduserdata(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false });
            });
    }

    CreatGroup =() =>{
        this.isAdminGroup = false;
        this.selectedUsers = [];
        var newgroup = {
            groupid:"0",
            groupname: "",
            description:"",
            userIdlist:"",
        } as UserGroup;
        
        this.setState({
            isModalEdit: true,
            showGroupModal: true,
            showUserModal: false,
            SelelctedGroup: newgroup
        });
    }
    
    RefreshUserData =() =>{
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
            errors:"",
            showGroupModal: false,
            showUserModal: false
        } as userState;
        this.setState(stateJson);

        this.loaduserdata(stateJson);
    }

    //Modal
    onClose =() =>{
        var stateJson = {
            blocking:true,
            showGroupModal: false,
            showUserModal: false,
            resultfound: false
        } as userState;
        this.setState (stateJson);

        this.loaduserdata(stateJson);
    }

    onEnterGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var group = this.state.SelelctedGroup;
        if(group != undefined){
            group.groupname = target.value;
            this.setState ({SelelctedGroup:  group});
        }
    }

    onEnterUserEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var user = this.state.SelelctedUser;
        if(user != undefined){
            user.email  = target.value;
            this.setState ({SelelctedUser:  user});
        }
    }

    onEnterGroupDesc = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var group = this.state.SelelctedGroup;
        if(group != undefined){
            group.description = target.value;
            this.setState ({SelelctedGroup:  group});
        }
    }

    onEditGroup = (row: UserGroup) => {
        if(row.groupname == 'Admin'){
            this.isAdminGroup = true;
        }
        else{
            this.isAdminGroup = false;
        }
        this.selectedUsers = row.userIdlist.split(";");
        this.setState({
            isModalEdit: true,
            showGroupModal: true,
            showUserModal: false,
            SelelctedGroup: row
        });
    }

    onEditUser = (row: User) => {
        this.selectedGroups = row.groupIdlist.split(";");
        this.setState({
            isModalEdit: true,
            showGroupModal: false,
            showUserModal: true,
            SelelctedUser: row
        });
    }

    OnhandleselectRows =(IsUserSelected:boolean, selectedRows:[]) =>{
        if(IsUserSelected){
            this.selectedUsers = selectedRows;
        }
        else{
            this.selectedGroups = selectedRows;
        }
    }

    saveUserGroup = () => {
        var selectedGroupIds: { [key: string]: boolean } = {};

        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
            errors:"",
            showGroupModal: false,
            showUserModal: false
        } as userState;
        this.setState(stateJson);

        this.selectedGroups.forEach(groupid => {
            selectedGroupIds[groupid] = true;
        })

        let url = API_URL + 'user/saveusergroup';
        axios.post(url, { userid: this.state.SelelctedUser?.userid, groupsIds: selectedGroupIds })
            .then(response => {
                if (response.status == 200) {
                    this.loaduserdata(stateJson);
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false });
            });
    }


    saveGroupUser =() =>{
        var selectedUserIds: { [key: string]: boolean } = {};

        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
            errors:"",
            showGroupModal: false,
            showUserModal: false
        } as userState;
        this.setState(stateJson);

        this.selectedUsers.forEach(userid => {
            selectedUserIds[userid] = true;
        })

        let url = API_URL + 'user/savegroup';
        axios.post(url, { groupid: this.state.SelelctedGroup?.groupid, groupname: this.state.SelelctedGroup?.groupname, description : this.state.SelelctedGroup?.description, userIds: selectedUserIds})
            .then(response => {
                if (response.status == 200) {
                    this.loaduserdata(stateJson);
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false });
            });
    }

    checkstatus =() =>{
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isadminuser){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }
    }

    componentDidMount(){
        this.RefreshUserData();
    }

    render(){
        this.checkstatus();
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
                    <div className="gap-page-content">
                        <div className="container">
                            <h1>User Management</h1>
                            <hr></hr>
                            <form name="groupForm">
                                <div className="row">
                                    <div className="col-md-1 padding-top-20">
                                        <h5>Groups</h5>
                                    </div>
                                    <div className="col-md-10 padding-top-20" >
                                            <span className="groupbutton" onClick={this.RefreshUserData}>Refresh</span>
                                            <span className="groupbutton" onClick={this.CreatGroup}>Create Group</span>
                                    </div>
                                </div>
                                {this.state.resultfound &&
                                    <div className="row">
                                        <UserGroupTable
                                            searchResults={this.allGroups}
                                            IsGroupTable = {true}
                                            HideSelectColumn={true}
                                            onEditGroup={(row: UserGroup) => this.onEditGroup(row)}
                                            onDeleteGroup={(row: UserGroup) => this.onDeleteGroup(row)}
                                        />
                                    </div>
                                }
                            </form>
                            
                            <form name="userForm">
                                <div className="row">
                                    <div className="col-md-1 margin-left-15 padding-top-30">
                                        <h5>Users</h5>
                                    </div>
                                </div>
                                
                                {this.state.resultfound &&
                                    <div className="row">
                                        <UserGroupTable
                                            searchResults={this.allUsers}
                                            IsUserTable = {true}
                                            HideSelectColumn={true}
                                            onEditUser={(row: User) => this.onEditUser(row)}
                                        />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>

                    <Modal show={this.state.showGroupModal}
                        size="lg"
                        animation={false}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.isModalEdit &&
                                    <div>
                                        <h1 className="lead intro">Edit a Group </h1>
                                    </div>
                                }
                                {!this.state.isModalEdit &&
                                    <div>
                                        <h1 className="lead intro">Create a Group</h1>
                                    </div>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form name="groupform">
                                <div className="row margin-top-10 margin-left-5 margin-right-5">
                                    <label htmlFor="groupName">Group Name</label> 
                                    <input type="text" className="form-control" name="groupName" id="groupName" disabled={this.isAdminGroup}
                                        value={this.state.SelelctedGroup?.groupname} required
                                        placeholder="Group Name"  onChange ={this.onEnterGroupName} />
                                </div>
                                <div className="row margin-top-10 margin-left-5 margin-right-5">
                                    <label htmlFor="description">Description</label> 
                                    <input type="text" className="form-control" name="description" id="description"
                                        value={this.state.SelelctedGroup?.description} required
                                        placeholder="Group Description" onChange ={this.onEnterGroupDesc} />
                                </div>
                            </form>
                            <br />
                            <form name="userForm">
                                <div className="row margin-left-5 ">
                                    <h5>Select Users</h5>
                                </div>
                                <div className="row margin-left-5 margin-right-5">
                                    <UserGroupTable
                                        searchResults={this.allUsers}
                                        IsUserTable={true}
                                        IsSelectTable ={true}
                                        SelectedRow = {this.state.SelelctedGroup?.groupid}
                                        OnSelect={(selectedRows: []) => this.OnhandleselectRows(true, selectedRows)}
                                        onEditUser={(row: User) => this.onEditUser(row)}
                                    />
                                </div>

                            </form>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-primary" onClick={this.saveGroupUser} >Save</button>
                            <button className="btn btn-outline-secondary" onClick={this.onClose}> Close </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showUserModal}
                        size="lg"
                        animation={false}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>
                                <div>
                                    <h1 className="lead intro">Edit a User </h1>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form name="groupform">
                                <div className="row margin-top-10 margin-left-5 margin-right-5">
                                    <label htmlFor="useremail">User Email</label> 
                                    <input type="text" className="form-control margin-left-5" name="description" id="useremail" disabled={true}
                                        value={this.state.SelelctedUser?.email} onChange ={this.onEnterUserEmail} />
                                </div>
                            </form>
                            <br />
                            <form name="userForm">
                                <div className="row margin-left-5 ">
                                    <h5>Select Groups</h5>
                                </div>
                                <div className="row margin-left-5 margin-right-5">
                                    <UserGroupTable
                                        searchResults={this.allGroups}
                                        IsGroupTable={true}
                                        IsSelectTable ={true}
                                        SelectedRow = {this.state.SelelctedUser?.userid}
                                        OnSelect={(selectedRows: []) => this.OnhandleselectRows(false, selectedRows)}
                                        onEditUser={(row: User) => this.onEditUser(row)}
                                    />
                                </div>

                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-primary" onClick={this.saveUserGroup} >Save</button>
                            <button className="btn btn-outline-secondary" onClick={this.onClose}> Close </button>
                        </Modal.Footer>
                    </Modal>

                </BlockUi>
            </div>
        )
    };
};
