import * as React from "react";
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import UserSession from '../../../services/UserSession'
import axios from "axios";
import validator from 'validator'
import VCF from '../../../domain/VCF'
import SampleList from "../../../domain/SampleList"
import Cohort from "../../../domain/Cohort"
import { CSVLink } from "react-csv";
import PlinkRowData from "../../../domain/PlinkRowData";
import { Bar } from 'react-chartjs-2';
import { UISref, Transition } from "@uirouter/react";
import API_URL from '../../../services/Enviroment'
import SearchableListData from "../../../domain/SearchableListData"
import CohortVisualizationTable from './CohortVisualizationTable';
import {removeOrcidCode} from '../../utils/gapCommon';
import './CohortVisualization.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type impactType = {
    name: string,
    id: string,
    selected: boolean
}

type VisualizationState = {
	blocking: boolean,
	pfilter: number, 
	ssridtype: string,
	depotsupport: boolean,
	selectedvcffile: string,
	selectedSamplelist: string,
	selectedCohort: string,
	resultfound: boolean,
	blockmessage: string,
	impacts: Array<impactType>,
	errors: string
}

type PlinkResult = {
    rowdata: PlinkRowData
}

  const barchartoption = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: '',
        },
    },
    scales: {
        y: {
            stacked: true
        }
    }
}

export default class CohortVisualization extends React.Component<Transition, VisualizationState> {
	usersession = UserSession.getUserSession();
	vcffiles = Array<VCF>();
	selectedVCFfilename = "";

	sampleLists = Array<SampleList>();
	sampleids = Array<SearchableListData>();

	colorTypes: { [key: string]: String } = {};
	cohorts = Array<Cohort>();
	visualizationResult = Array<PlinkRowData>();
	downloadResult = Array<PlinkRowData>();

	barchartdata = {
        labels: Array<string>(),
        datasets:[{
            label: '',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: Array<number>()
        }]
    }
    barchartlabels = Array<string>();

	constructor(props: Transition) {
		super(props);
		if (sessionStorage.token == null || sessionStorage.token == "") {
			let transition = props;
			const $state = transition.router.stateService;
			$state.go('home');
		}

		this.state = {
			blocking: false,
			pfilter: 10,
			ssridtype: "s",
			depotsupport: true,
			selectedvcffile: "",
			selectedCohort: "",
			selectedSamplelist: "",
			resultfound: false,
			blockmessage: "Loading",
			impacts: Array<impactType>(),
			errors: ""
		};
	}

	onChangeList =(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
		this.setState ({selectedSamplelist: target.value});
	}

	onChangeCohort=(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;

		this.setState ({selectedCohort: target.value});
	}

	onSsridtypeChange = (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ssridtype:target.value});
    }

	onEnterPfilter = (event: React.FormEvent <HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState ({pfilter: parseFloat(target.value)});
    }

	onReloadCohorts =() => {   //will add a call to get latest cohorts from DEPOT.
		if(this.usersession.isverified){
            this.setState({blocking: true, blockmessage:"Refresh cohorts..."}); 
            let url = API_URL + 'genomics/reloadDepotData';
            axios.post(url, {email:this.usersession.email})
                .then(response => {
                    this.setState({blocking: false}); 
                    this.usersession.Depot_cohorts = response.data;
	        	    sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                 //   this.cohorts_cases = this.usersession.Depot_cohorts;
                  //  this.cohorts_controls = this.usersession.Depot_cohorts;
                })
                .catch(error => {
                    console.error('There was an error!' + error.message);
                    this.setState ({errors: 'There was an error!' + error.message, blocking: false});
                });
		}
	}

	onChangevcf = (event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
      
		var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
			selectedvcffile: target.value,
            errors:""
        } as VisualizationState;

		this.setState(stateJson);

    	this.visualizationResult = [];
		var selectedVCFfile = JSON.parse(target.value) as VCF;
		var vcffilename = selectedVCFfile.filename;
		if (selectedVCFfile.owner != null && selectedVCFfile.owner != "system") {
			vcffilename = selectedVCFfile.owner + "/" + vcffilename;
		}
		this.selectedVCFfilename = vcffilename;
		this.loadSampleList(stateJson);
	}

	loadvcffile = () => {
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
            errors:""
        } as VisualizationState;

        this.setState(stateJson);

        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, {type:'vcf'})
            .then(response => {
                this.vcffiles = response.data;
                var defaultvcffile=this.vcffiles[0];
                for (var i = 0; i < this.vcffiles.length; i++) {
                    var vcf = this.vcffiles[i];
                    if (vcf.isdefault) {
                        defaultvcffile = vcf;
                        break;
                    }
                }

				var vcffilename = defaultvcffile.filename;
				if (defaultvcffile.owner != null && defaultvcffile.owner != "system") {
					vcffilename = defaultvcffile.owner + "/" + vcffilename;
				}

				this.selectedVCFfilename = vcffilename;
                stateJson.selectedvcffile = JSON.stringify(defaultvcffile);
				this.loadSampleList(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
            });
    }

	loadSampleList =(stateJson: VisualizationState) => {
		var selectedvcf = JSON.parse(stateJson.selectedvcffile) as VCF;
		let url = API_URL +'listmanagement/getList';
        axios.get(url, {params: {vcfid: selectedvcf.vcfid}})
            .then((response) => {
				var userlist = response.data;
        		var userSampleList = userlist.filter(function(list : SampleList){
        			return (list.listtype == 'Sample');
        		});
        		
                this.sampleLists = userSampleList;

				if(this.usersession.isverified){
					if(selectedvcf.hasmetadata){
						stateJson.depotsupport = true;
					}
					else{
						stateJson.depotsupport = false;
						stateJson.ssridtype="s";
					}
				}
				
				stateJson.blocking = false;
                this.setState(stateJson);
            })
            .catch((error) => {
                console.error("There was an error! " + error.message);
                this.setState({errors:error.message});
            });
	}

	OnSearchData =() => {
		if(this.state.ssridtype == 's'){
			var selectedList = JSON.parse(this.state.selectedSamplelist) as SampleList;
			var controlsdata = selectedList.totallistcontent;
			this.getVisualizationResults(controlsdata);
		}
		else {
			if(this.state.selectedCohort  != null && this.state.selectedCohort != ""){
				var controlsdata = (JSON.parse(this.state.selectedCohort) as Cohort).ssrids;
				this.getVisualizationResults(controlsdata);
			}
		}
	}

	onFilterdata =() => {
		var filter_value = this.state.pfilter;
		this.downloadResult = this.visualizationResult.filter(function(data: PlinkRowData){
				return (data.TWO_ALT_GENO_CTS > filter_value);
		});
		this.createBarchart();
		this.setState({	blockmessage: "", errors: ""});
	}

	onImpactChecked =(event: React.FormEvent<HTMLInputElement>) => {
		var target = event.target as HTMLInputElement;
        var imapctid=target.id as string;  
        var currentimapct = this.state.impacts.find(x=>x.id == imapctid) as impactType;
        currentimapct.selected = !currentimapct.selected;
        var selectedimpacts = this.state.impacts.filter(x=>x.selected);

		var filter_value = this.state.pfilter;
		var filterResult = this.visualizationResult.filter(function(data: PlinkRowData){
			return (data.TWO_ALT_GENO_CTS > filter_value);
		});
        
        if(selectedimpacts.length == 0){
            this.downloadResult = filterResult;
        }
        else {
            this.downloadResult = filterResult.filter(function (item) {
                var re = false;

                var itemImpact = item.Impact;
                if (itemImpact == undefined || itemImpact == null || itemImpact.length == 0) itemImpact = "Other";

                var combineimpacts = itemImpact.split("&");
                for (var key in selectedimpacts) {
                    var f = selectedimpacts[key];
                    if (combineimpacts.includes(f.id)) {
                        re = true;
                        break;
                    }
                }
                return re;
            });
        }

        this.setState({impacts: this.state.impacts});
	}

	getVisualizationResults =(cohortdata:string) => {
		var stateJson = {
			blocking:true,
			blockmessage:"Loading ... ",
			resultfound: false,
			errors:""
		} as VisualizationState;

		this.setState(stateJson);
		let url = API_URL +'visualization/getVisualizationResults';
        axios.post(url, {"C1": cohortdata, "vcffile": this.selectedVCFfilename})
            .then(response => {
				stateJson.blocking = false;
				stateJson.resultfound = true;

				this.visualizationResult = this.PrepareData(response.data.Results);
				this.onFilterdata();
				this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
            });
	}

	createBarchart =() =>{
		var bardata =Array<number>();
        this.barchartdata.datasets=[];
        this.barchartdata.labels=[];

		var countArray: { [key: string]: number }={};
		
		for (var i = 0; i < this.downloadResult.length; i++) {
			var olddata = this.downloadResult[i];
			
			var imapctVal = olddata.Impact;
			if (imapctVal == undefined || imapctVal == null || imapctVal.length == 0) imapctVal="Other";
			
			if(imapctVal.includes("&")){
				var combineimpacts = imapctVal.split("&");
				for (var j = 0; j < combineimpacts.length; j++) {
					var im = combineimpacts[j];
					if (countArray[im]) {
						countArray[im]++;
					 }
					 else {
						   countArray[im] = 1;
					}
				}
			}
			else{
				if (countArray[imapctVal]) {
					countArray[imapctVal]++;
				 }
				 else {
					   countArray[imapctVal] = 1;
				}
			}
		}

		for (var i = 0; i < countArray.length; i++) {
			bardata.push(countArray[i]);
		}

		var impacts = new Array<impactType>();
		for (var prop in countArray) {
            if (countArray.hasOwnProperty(prop)) {
                bardata.push(countArray[prop]);
                this.barchartdata.labels.push(prop);
                var filter = { 'id': prop, 'name': prop, 'selected': false };
				impacts.push(filter);
            }
		}
		this.setState({	impacts:impacts});

		var barchartdata = {
            label: '',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: bardata
        }

        this.barchartdata.datasets.push(barchartdata); 
	}
	

	PrepareData=(data :  Array<PlinkResult>) => {
		var newdata = [];
		for (var i = 0; i < data.length; i++) {
			var olddata = (data[i] as PlinkResult).rowdata;
			olddata.BP=parseFloat(olddata.BP.toString());
			olddata.TWO_ALT_GENO_CTS=parseFloat(olddata.TWO_ALT_GENO_CTS.toString());
			
			if(olddata.hasOwnProperty("P")){
				olddata.P=parseFloat(olddata.P.toString());
			}
			olddata.AltAllele_display = olddata.AltAllele;
			if(olddata.hasOwnProperty("AltAllele") && olddata.AltAllele.length > 20){
				olddata.AltAllele_display = olddata.AltAllele.substring(0,10) + "....";
			}
			
			olddata.RefAllele_display = olddata.RefAllele;
			if(olddata.hasOwnProperty("RefAllele") && olddata.RefAllele.length > 20){
    		    olddata.RefAllele_display = olddata.RefAllele.substring(0,10) + "....";
			}

			olddata.AAChange_display = olddata.AAChange;
			if(olddata.hasOwnProperty("AAChange") && olddata.AAChange.length > 20){
    		    olddata.AAChange_display = olddata.AAChange.substring(0,10) + "....";
			}
			olddata.NTChange_display = olddata.NTChange
			if(olddata.hasOwnProperty("NTChange") && olddata.NTChange.length > 20){
    		    olddata.NTChange_display = olddata.NTChange.substring(0,10) + "....";
			}
			
			newdata.push(olddata);
		};

		return newdata;
	}

	componentDidMount() {
		this.loadvcffile();
	}

	render() {
		removeOrcidCode("VariantSearch", this.props);
		this.usersession = JSON.parse(sessionStorage.userSession);
		this.cohorts = this.usersession.Depot_cohorts;
		return (
			<div>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
					<div className="gap-page-content">
						<div className="container">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className='breadcrumb-item'>
										<UISref to="home"><a>Home</a></UISref>
									</li>
									<li className='breadcrumb-item'>
										<UISref to="variants"><a>Variant</a></UISref>
									</li>
									<li className="breadcrumb-item active" aria-current="page">Cohort Visualization</li>
								</ol>
							</nav>
							<form name="vcfForm">
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="vcffile">VCF File</label>
										<div className="form-group">
											<select
												className="form-select"
												value={this.state.selectedvcffile}
												onChange={this.onChangevcf}>
												{
													Object.entries(this.vcffiles).map(([key, value], i) => (
														<option key={key} value={JSON.stringify(value)}>{value.displayname}</option>
													))
												}
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<label htmlFor="pfilter">Alt Allele Count &gt;  </label>
										<div>
											<div style={{display:"inline-block", width:"40%"}}>
												<input type="number" className="form-control" id="pfilter" name="input"
													placeholder="input a number (0-100)" value={this.state.pfilter} min="0"
													max="100" width="60px" step="1" required
													onChange={this.onEnterPfilter} />
											</div>
											<div className="filterbutton">
												<button id="addfilter" type="button" className="btn btn-primary" onClick={this.onFilterdata} disabled={this.visualizationResult.length==0} >Filter</button>
											</div>
										</div>
									</div>
								</div>
								{/* {this.usersession.isverified &&
									<div className="row margin-top-15" >
										<div className="col-md-4">
											<input id="cb_upload" type="radio" name="ssridtype" value="s" checked={this.state.ssridtype === "s"} onChange={this.onSsridtypeChange} />
                                            <span> <label htmlFor="cb_upload" >Sample List</label></span>
										</div>
										<div className="col-md-4" >
                                            <div>
                                                <input id="cb_cohort" type="radio" name="ssridtype" value="c" disabled={!this.state.depotsupport} checked={this.state.ssridtype === "c"} onChange={this.onSsridtypeChange} />
                                                <span className="margin-left-9" >
													<label htmlFor="cb_cohort" >Import Cohorts from  DEPOT &nbsp;&nbsp;</label>
												</span>
                                                <a  className="link-primary" onClick={() => this.onReloadCohorts()} >Reload Cohorts</a>
                                            </div>
                                        </div>
									</div>
								} */}

								<div className="row">
									<div className="col-md-6" >
										{/* <fieldset className="custom_fieldset" style={{ width: "350px" }}  disabled={this.state.ssridtype=='c'} > */}
											<div className="form-group margin-top-10" >
												<label htmlFor="gcontrols">Cohort Identifiers</label> <br />
												<select
													id="gcontrols"
													className="form-select"
													value={this.state.selectedSamplelist}
													onChange={this.onChangeList}>
													<option value="">-- please choose a sample list --</option>
													{
														Object.entries(this.sampleLists).map(([key, value], i) => (
															<option key={key} value={JSON.stringify(value)}>{value.listname}</option>
														))
													}
												</select>
											</div>
										{/* </fieldset> */}
									</div>
									{/* {this.usersession.isverified &&
										<div className="col-md-4">
											<fieldset className="custom_fieldset" style={{ width: "650px" }} disabled={this.state.ssridtype == 's'}>
												<div className="form-group margin-top-10" >
													<label htmlFor="dd_cohort">Cohort</label>
													<select
														id="cohort_sampleList"
														placeholder="Select a Cohort ..."
														className="form-select"
														value={this.state.selectedCohort}
														onChange={this.onChangeCohort}>
														<option value="">Select a Cohort ...</option>
														{
															Object.entries(this.cohorts).map(([key, value], i) => (
																<option key={key} value={JSON.stringify(value)}>{value.name}</option>
															))
														}
													</select>
												</div>
											</fieldset>
										</div>
									} */}
								</div>
								<br></br>
								<div className="row">
									<div className="col-md-6">
										<button id="search" type="button" onClick={this.OnSearchData}
											className=" btn btn-primary" 
											disabled={(this.state.ssridtype == 's' && this.state.selectedSamplelist == '') ||
													  (this.state.ssridtype == 'c' && this.state.selectedCohort == '')  || 
													  (this.state.ssridtype == '' )
													 }>Submit
										</button>
									</div>
								</div>

								{this.state.resultfound &&
									<div className="panel-body margin-top-20">

										<div className="row">
											<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 chartbar ">
                                       			 <Bar data={this.barchartdata} options={barchartoption} />
											</div>

											<div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 " >
												
												<div className="col-md-10 col-lg-10 col-sm-10 col-xs-10" >
													{this.state.impacts.map((item, index) => (
														<div className="impactcheckbox" key={index}>
															<input
																id={item.id}
																type="checkbox"
																name={item.name}
																value={item.name}
																checked={item.selected}
																onChange={this.onImpactChecked}
															/>
															<label htmlFor={item.id}>&nbsp;{item.name}</label>
														</div>
													))}
                                    		    </div>

											</div>

										</div>

										<div className="margin-bottom-10">
											<CSVLink data={this.downloadResult} className="btn btn-success downloadbutton "
												filename={"CohortVisualization.csv"}
												headers={[
													{ label: "Position", key: "BP" },
													{ label: "Impact", key: "Impact" },
													{ label: "AA Change", key: "AAChange" },
													{ label: "Ref Allele", key: "RefAllele" },
													{ label: "Alt Allele", key: "AltAllele" },
													{ label: "Gene Name", key: "GeneName" },
													{ label: "Gene ID", key: "GeneID" },
													{ label: "Protein ID", key: "ProteinID" },
													{ label: "Protein Name", key: "ProteinName" },
													{ label: "HOM REF CT", key: "HOM_REF_CT" },
													{ label: "HET REF ALT CTS", key: "HET_REF_ALT_CTS" },
													{ label: "TWO ALT GENO CTS", key: "TWO_ALT_GENO_CTS" },
													{ label: "MISSING CT", key: "MISSING_CT" },
												]}>
												<span className="glyphicon glyphicon-save "></span>Download
											</CSVLink>
										</div>
										<div>
                                            <CohortVisualizationTable searchResults={this.downloadResult}  />
                                        </div>
									</div>
								}
							</form>
						</div>
					</div>

				</BlockUi>
			</div>
		);
    }
  }