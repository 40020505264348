import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter'
import {calculateTablePage} from '../../utils/gapCommon';
import $ from 'jquery';

var selectedpage = 1;
var selectedrowid = [];
var select_id = "";
var select_id2 = "";

export default class GWASTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

   popup_multipHyperlink = function(g_id, id2, grouptype){
		select_id2="";
		select_id="";
		
		$(".custom-menu").finish().toggle(100).css({
		        top: window.event.pageY + "px",
		        left: window.event.pageX + "px"
		    }); // In the right position (the mouse)
		
		$('.custom-menu li').hide();
		$('.custom-menu li').filter('[data-label=' + grouptype + ']').show();
	
		if(id2 != null){
			select_id2 = id2.split("-")[0];
		}
		
		var first_id = g_id.split("-");
		
	  select_id = first_id[0];
	}

 gotoextern = function(target){
		switch (target) {
			case "ncbi_gene":
				window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
				break;
			case "geo":
				window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
				break;
			case "kegg":
				window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
				break;
			case "ncbi_protein":
				window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
				break;
			case "uniprot":
				window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
				break;
			case "tbrnat":
				window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
				break;
      default:
        break;
		}
	}

  GWASResultColumns = [
    { dataField: 'P', text: 'P-Value', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' },
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'BP', text: 'Position', sort: true,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', width:'70px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><a class="link-primary" href={() => false} onClick={() => {this.openGen(row);}}>{row.BP}</a></div>;
        }
    }, 
    { 
        dataField: 'Impact', text: 'Impact', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },  
    { 
        dataField: 'AAChange', text: 'AA Change', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'RefAllele', text: 'Ref Allele', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'AltAllele', text: 'Alt Allele', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'GeneName', text: 'Gene Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'GeneID', text: 'Gene ID', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><a className="link-primary"  href={() => false} onClick={() => {this.popup_multipHyperlink(row.GeneID,row.RefseqID, 'Gene'); }}>{row.GeneID}</a></div>;
        }
    }, 
    { 
        dataField: 'ProteinID', text: 'Protein ID', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><a className="link-primary"  href={() => false} onClick={() => this.popup_multipHyperlink(row.ProteinID, null, 'Protein')} >{row.ProteinID}</a></div>;
        }
    }, 
    { 
        dataField: 'ProteinName', text: 'Protein Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'130px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'Count', text: 'Count Cases/ Controls', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          if(this.props.assoc === 'GLM'){
            return <div>{`${row.A1_CASE_CT}/${row.A1_CTRL_CT}`}</div>;
          }
          return <div>{`${row.C_A}/${row.C_U}`}</div>;
        }
    }
  ];

  
  openGen = (selectedRow) => {
    this.props.openGen(selectedRow);
  }

  // calculatePage = () => {
  //   if(this.node === undefined) return;

  //   var datasource = this.node.paginationContext;
  //   var { currSizePerPage, currPage } = datasource;
  //   var sortedData = datasource.props.data;

  //   if(this.props.selectedrowid === "") {
  //     selectedpage = currPage;
  //     return;
  //   }

  //   selectedrowid = [this.props.selectedrowid];

  //   var pos = 0;
   
  //   for (var i = 0; i < sortedData.length; i++) {
  //     if (sortedData[i].id === (this.props.selectedrowid)) {
  //       selectedpage = parseInt(pos / currSizePerPage) + 1;
  //       break;
  //     } else {
  //       pos++;
  //     }
  //   }
  // };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  handleClickOutside = evt => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  render(){
    [selectedpage, selectedrowid] = calculateTablePage (this.node, this.props.selectedrowid, "id");
    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { backgroundColor: "#c8e6c9" },
      selected: selectedrowid,
    };

    const defaultSorted = [{
      dataField: 'P',
      order: 'asc'  
    }];

    const GWSResultPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.plinkResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
      <div>
        <div>
          <PaginationProvider pagination={paginationFactory(GWSResultPagination)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable 
                  bootstrap4
                  keyField="id"
                  ref={ n => this.node = n }
                  data={this.props.plinkResults}
                  columns={this.GWASResultColumns}
                  selectRow={ selectedRow }
                  defaultSorted={ defaultSorted } 
                  {...paginationTableProps}
                />
                <div className="row react-bootstrap-table-pagination">
                  <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                </div>
              </div>
            )}
          </PaginationProvider>
      </div>
      <div>
          <ul className='custom-menu'>
              <li data-label="Gene"><a href={() => false} onClick={() => this.gotoextern('ncbi_gene')} >NCBI Gene Database</a></li>
              <li data-label="Gene"><a href={() => false} onClick={() => this.gotoextern('geo')} >NCBI Gene Expression Omnibus</a></li>
              <li data-label="Gene"><a href={() => false} onClick={() => this.gotoextern('kegg')} >KEGG Genes Database</a></li>
              <li data-label="Gene"><a href={() => false} onClick={() => this.gotoextern('tbrnat')} >TBRNAT</a></li>
              <li data-label="Protein"><a href={() => false} onClick={() => this.gotoextern('ncbi_protein')} >NCBI Protein</a></li>
              <li data-label="Protein"><a href={() => false} onClick={() => this.gotoextern('uniprot')} >UniProt</a></li>
          </ul>
        </div>
    </div>
    );
  }
};
