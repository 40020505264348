import { Transition } from "@uirouter/react";
import * as React from "react";
import {removeOrcidCode} from './utils/gapCommon';

export default class FAQ extends React.Component<Transition> {
  
  render() {
    removeOrcidCode("faq", this.props);
    return (
      <div className="gap-page-content">
        <div className="container">
          <h3>FAQ</h3>
          <hr />
          <b>Which version for the TB genome are you using?</b>
          <p>We are support both the H37RV reference sequence: NC000962 which can be found at: <a href="https://www.ncbi.nlm.nih.gov/nuccore/NC_000962" target="_blank">https://www.ncbi.nlm.nih.gov/nuccore/NC_000962</a></p>
          <p>as well as the Broad H37Rv NC018143 version of the TB genome which can be found at: <a href="https://www.ncbi.nlm.nih.gov/nuccore/NC_018143" target="_blank">https://www.ncbi.nlm.nih.gov/nuccore/NC_018143</a></p>

          <b>How can I get help using the application or technical support?  </b>
          <p>Please send an e-mail to:  <a href="mailto:TBPortalSupport@nih.gov">TBPortalSupport@nih.gov</a></p>

          <b>Can I use this application for making clinical treatment decisions?</b>
          <p>No. This application is for research purposes only and is not intended for clinical use. </p>

          <b>Where can find more information about the tools and algorithms used by the portal?</b>
          <p>All of the algorithms and tools are publically available.  Below is a list of the tools and links to source code and publications.  These tools were developed by groups outside of NIH / NIAID and have not been modified.  </p>
          <ul>
            <li>
              <b>KvarQ</b>
              <i><p>BMC Genomics. 2014 Oct 9;15:881. doi: 10.1186/1471-2164-15-881.</p>
                <p><b>KvarQ: targeted and direct variant calling from fastq reads of bacterial genomes.</b></p>
                <p>Steiner A, Stucki D, Coscolla M, Borrell S, Gagneux S </p>
                <p><a href="https://bmcgenomics.biomedcentral.com/articles/10.1186/1471-2164-15-881" target="_blank">https://bmcgenomics.biomedcentral.com/articles/10.1186/1471-2164-15-881</a></p>
                <p><a href="https://github.com/kvarq/kvarq" target="_blank">https://github.com/kvarq/kvarq</a>  (source)</p></i>
            </li>
            <li>
              <b>myKrobe</b>
              <i><p>Nat Commun. 2015 Dec 21;6:10063. doi: 10.1038/ncomms10063.</p>
                <p><b>Rapid antibiotic-resistance predictions from genome sequence data for Staphylococcus aureus and Mycobacterium tuberculosis.</b></p>
                <p>Bradley P, Gordon NC, Walker TM, Dunn L, Heys S, Huang B, Earle S, Pankhurst LJ, Anson L, de Cesare M, Piazza P, Votintseva AA, Golubchik T, Wilson DJ, Wyllie DH, Diel R, Niemann S, Feuerriegel S, Kohl TA, Ismail N, Omar SV, Smith EG, Buck D, McVean G, Walker AS, Peto TE, Crook DW, Iqbal Z. </p>
                <p><a href="https://www.nature.com/articles/ncomms10063" target="_blank">https://www.nature.com/articles/ncomms10063</a></p>
                <p><a href="https://github.com/iqbal-lab/Mykrobe-predictor" target="_blank">https://github.com/iqbal-lab/Mykrobe-predictor</a>  (source for command line tool)</p>
                <p>also see <a href="http://www.mykrobe.com/" target="_blank">http://www.mykrobe.com/</a>  for a desktop application. </p>
              </i>
            </li>
            <li>
              <b>PLINK</b>
              <i><p>Gigascience. 2015 Feb 25;4:7. doi: 10.1186/s13742-015-0047-8. eCollection 2015.</p></i>
              <i><p><b>Second-generation PLINK: rising to the challenge of larger and richer datasets.</b></p></i>
              <i><p>Chang CC1, Chow CC2, Tellier LC3, Vattikuti S2, Purcell SM4, Lee JJ5.</p></i>
              <i><p><a href="https://gigascience.biomedcentral.com/articles/10.1186/s13742-015-0047-8" target="_blank">https://gigascience.biomedcentral.com/articles/10.1186/s13742-015-0047-8</a> </p></i>
              <i><p><a href="https://www.cog-genomics.org/plink2" target="_blank">https://www.cog-genomics.org/plink2</a>   (download, user guide, and source)</p></i>
            </li>
            <li>
              <b>TBProfiler</b>
              <i><p>Genome Med. 2015 May 27;7(1):51. doi: 10.1186/s13073-015-0164-0. eCollection 2015.</p>
                <p><b>Rapid determination of anti-tuberculosis drug resistance from whole-genome sequences.</b></p>
                <p>Coll F, McNerney R, Preston MD, Guerra-Assunção JA, Warry A, Hill-Cawthorne G, Mallard K, Nair M, Miranda A, Alves A, Perdigão J, Viveiros M, Portugal I, Hasan Z, Hasan R, Glynn JR, Martin N, Pain A, Clark TG.</p>
                <p><a href="https://genomemedicine.biomedcentral.com/articles/10.1186/s13073-015-0164-0" target="_blank">https://genomemedicine.biomedcentral.com/articles/10.1186/s13073-015-0164-0</a> </p>
                <p><a href="https://github.com/jodyphelan/TBProfiler" target="_blank">https://github.com/jodyphelan/TBProfiler</a> (source)</p></i>

            </li>
          </ul>
        </div>
      </div>
    );
  }
}
