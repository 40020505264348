import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import API_URL from './services/Enviroment';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    //console.info(`[request] [${JSON.stringify(config)}]`);
    if (sessionStorage.token != null && sessionStorage.token != "") {
        const token = sessionStorage.getItem("token");
        config.headers!.Authorization = 'Bearer ' + token;
    }

    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  //  console.info(`[response] [${JSON.stringify(response)}]`);

    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    var response = error.response;
    if (response?.status === 401) {
        var url = API_URL +'login/refresh';
        axios.post(url, { token: sessionStorage.token })
            .then(function (r) {
                if (r.data != null) {
                    sessionStorage.token = r.data;
                    return new Promise((resolve) => {
                        resolve(axios(error.config))
                    })
                } else {
                    return Promise.reject(error)
                }
            });
    } else {
        return Promise.reject(error)
    }
    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}