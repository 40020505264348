import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../utils/tableHeaderSortFormatter'
import * as BSIcon from "react-icons/bs"

export default class UserProfileTable extends React.Component {
  selectedRows = [];

  constructor(props) {
    super(props);
    console.log(props);

    //pre-select userid
    if(this.props.IsUserTable && this.props.SelectedRow !== undefined){
      this.props.searchResults.forEach((user) => {
        var usergroupslist = user.groupIdlist.split(";");

        if (usergroupslist.includes(this.props.SelectedRow)) {
          this.selectedRows.push(user.userid);
        }
      });
    }

    //pre-select group
    if (this.props.IsGroupTable && this.props.SelectedRow !== undefined) {
      //list group
      if(this.props.IsListGroup){
        var grouplist = this.props.SelectedRow.split(",");
        this.props.searchResults.forEach((group) => {
          if (grouplist.includes(group.groupid)) {
            this.selectedRows.push(group.groupid);
          }
        });
      }
      else if(this.props.IsVCFGroup){
        this.selectedRows = this.props.SelectedRow;  //array
      }
      else{     //pre-select user group
        this.props.searchResults.forEach((group) => {
          var groupuserlist = group.userIdlist.split(";");

          if (groupuserlist.includes(this.props.SelectedRow)) {
            this.selectedRows.push(group.groupid);
          }
        });
      }
    }
  }

//Define Columns for Group, User and List
  GroupColumns = [
    { dataField: 'groupname', text: 'Group Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'200px'},
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    }, 
    { 
        dataField: 'description', text: 'Description', sort: false,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', maxwidth:'500px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'userNamelist', text: 'Users in Group', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle'  }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.IsSelectTable,
    },  
    { 
        dataField: '', text: '', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.IsSelectTable,
        formatter: (cell, row) => {
            return <div>
                      <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditGroup(row)}>
                        <BSIcon.BsPencil className="margin-bottom-3"  title="Edit Group" />
                      </button>
                      {(row.userNamelist === undefined || row.userNamelist ==="") &&
                        <button type="button" className="btn btn-danger btn-sm"  onClick={() => this.onDeleteGroup(row)}>
                          <BSIcon.BsTrashFill className="margin-bottom-3"  title="Delete Group" />
                        </button>
                      }
                    </div>;
        }
    }
  ];

  UserColumns = [
    { dataField: 'email', text: 'User Email', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'250px' },
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    }, 
    { 
        dataField: 'firstname', text: 'First Name', sort: true,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', maxwidth:'150px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap' 
    }, 
    { 
        dataField: 'lastname', text: 'Last Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'150px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    { 
      dataField: 'organization', text: 'Organization', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'250px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },  
    { 
      dataField: 'groupNamelist', text: 'User Groups', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
        dataField: '', text: '', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.IsSelectTable,
        formatter: (cell, row) => {
            return <div>
                      <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditUser(row)}>
                        <BSIcon.BsPencil className="margin-bottom-3"  title="Assign User Group" />
                      </button>
                    </div>;
        }
    }
  ];

  ListColumns = [
    { dataField: 'listname', text: 'List Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'200px' },
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'listtype', text: 'List Type', sort: true,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap' , headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'username', text: 'Owner', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    { 
      dataField: 'listsource', text: 'List Source', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },  
    { 
      dataField: 'totallistnumber', text: 'List Number', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'createdate', text: 'Create Date', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'vcf', text: 'VCF', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle',width:'400px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'groupnames', text: 'User Group', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
        dataField: '', text: '', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'130px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.IsSelectTable,
        formatter: (cell, row) => {
            return <div>
                    <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onDownloadList(row)}>
                      <BSIcon.BsDownload className="margin-bottom-3"  title="Download List" />
                    </button>
                    <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditList(row)}>
                      <BSIcon.BsPencil className="margin-bottom-3"  title="Edit List" />
                    </button>
                    <button type="button" className="btn btn-danger btn-sm"  onClick={() => this.onDeleteList(row)}>
                      <BSIcon.BsTrashFill className="margin-bottom-3"  title="Delete List" />
                    </button>
                    
                  </div>;
        }
    }
  ];

  onDeleteGroup =(row) =>{
    var r = window.confirm("You will delete Group " + row.groupname + ",Please confirm it." );
		if (r === false) {
		    return;
		}
    this.props.onDeleteGroup(row);
  }

  onEditGroup =(row) =>{
    this.props.onEditGroup(row);
  }

  onEditUser =(row) =>{
    this.props.onEditUser(row);
  }

  handleOnSelect = (row, isSelect) => {
    var selectedid = "";
    if(row.hasOwnProperty("groupid")){
      selectedid = row.groupid;
    }
    else{
      selectedid = row.userid;
    }
    
    if(isSelect){
      this.selectedRows.push(selectedid);
    }
    else{
      this.selectedRows = this.selectedRows.filter(function(r) { 
        return r !== selectedid
      });
    }
  
    this.props.OnSelect(this.selectedRows);
  }

  handleOnSelectAll = (isSelect, rows) => {
    this.selectedRows = [];
    if (isSelect) {
      rows.forEach(row => {
        if(row.hasOwnProperty("groupid")){
          this.selectedRows.push(row.groupid);
        }
        else{
          this.selectedRows.push(row.userid); 
        }
      });
    }
    this.props.OnSelect(this.selectedRows);
  }

  onDeleteList =(row)=>{
    var r = window.confirm("Do you want to delete list " + row.listname);
		if (r === false) {
		    return;
		}

    this.props.onDeleteList(row);
  }



  onEditList=(row)=>{
    this.props.onEditList(row);
	}

  onDownloadList=(row)=>{
    this.props.onDownloadList(row);
	}
  
  render(){
    const tablePagination = {
      custom: true,
      page: 1,
      totalSize: this.props.searchResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    const selectTableRow = {
      mode: "checkbox",
      //clickToSelect: true,
      hideSelectColumn: this.props.HideSelectColumn,
      selected: this.selectedRows,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };

    return (
      <div>
        {this.props.IsGroupTable && (
          <div>
            <BootstrapTable
              bootstrap4
              keyField="groupid"
              ref={(n) => (this.node = n)}
              data={this.props.searchResults}
              columns={this.GroupColumns}
              selectRow={selectTableRow}
            />
          </div>
        )}
        {this.props.IsUserTable && (
          <div>
            <BootstrapTable
              bootstrap4
              keyField="userid"
              ref={(n) => (this.node = n)}
              data={this.props.searchResults}
              columns={this.UserColumns}
              selectRow={selectTableRow}
            />
          </div>
        )}
        {this.props.IsListTable &&
          <div>
              <PaginationProvider pagination={paginationFactory(tablePagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      bootstrap4
                      keyField="listid"
                      ref={ n => this.node = n }
                      data={this.props.searchResults}
                      columns={this.ListColumns}
                      selectRow={ selectTableRow }
                      {...paginationTableProps}/>
                    
                    <div className="row react-bootstrap-table-pagination">
                      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
          </div>
        }
      </div>
    );
  }
};