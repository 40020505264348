import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter'
import * as BSIcon from "react-icons/bs"
import {calculateTablePage} from '../../utils/gapCommon';
import "./VCFManagement.css";

var selectedpage = 1;
var selectedrowid = [];

export default class VCFFilesTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  onReferenceChange =(event, row) =>{
    var target = event.target;
    if(target.value !== undefined){
       var new_value = target.value;
       var newRow = JSON.parse(JSON.stringify(row));
       newRow.reference = new_value;
       this.props.onUpdateRowData(newRow);
     }
   }

   onDefaultChange =  (event, row) => {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.isdefault = new_value;
    this.props.onUpdateRowData(newRow);
   }

  onMetadataChange =(event, row)=> {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.hasmetadata = new_value;
    this.props.onUpdateRowData(newRow);
  }

  onDeleteVCF =(row) =>{
    this.props.onDeleteVCF(row);
  }

  onEditVCF =(row) =>{
    this.props.onEditVCF(row);
  }

  VCFFilesColumns = [
    { dataField: 'filename', text: 'VCF File', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle' },
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    }, 
    { 
        dataField: 'vcf_description', text: 'Description', sort: false,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', width:'200px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'reference', text: 'Reference', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'160px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', 
        formatter: (cell, row) => {
          return (
            <div>
              <select
                className="form-select"
                value={row.reference}
                onChange={event => this.onReferenceChange(event, row)}>
                {Object.entries(this.props.referenceData).map(([key, value], i) => (
                  <option key={key} value={value.referenceName}>{value.referenceName}</option>
                ))}
              </select>    
            </div>
          );
        }
    },  
    { 
        dataField: 'owner', text: 'Owner', headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'120px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        sort: true
    }, 
    { 
        dataField: 'groupStr', text: 'User Group', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'150px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap' 
    }, 
    { 
        dataField: 'isdefault', text: 'Default', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', 
        hidden: this.props.isAdmin,
        formatter: (cellContent, row) => {
          if(row.isdefault){
            return(
              <div className="checkbox disabled">
                <label>
                  <input type="checkbox" checked={ row.isdefault } />
                </label>
              </div>
            )
          }
          else{
            return (
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={ row.isdefault } onChange={event => this.onDefaultChange(event, row)} />
                </label>
              </div>
            )
          }
        }
    }, 
    { 
        dataField: 'hasmetadata', text: 'Support Depot', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        hidden: this.props.isAdmin,
        formatter: (cellContent, row) => {
          return (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={ row.hasmetadata } onChange={event => this.onMetadataChange(event, row)} />
              </label>
            </div>
          )
        }
    }, 
    { 
        dataField: '', text: '', sort: false, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' },
        headerClasses: "tablecellwrap", classes: 'tablecellwrap',
        formatter: (cell, row) => {
            return <div>
                    <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditVCF(row)}>
                      <BSIcon.BsPencil className="margin-bottom-3"  title="Assign User Group" />
						      	</button>
						      	<button type="button" className="btn btn-danger btn-sm"  onClick={() => this.onDeleteVCF(row)}>
                      <BSIcon.BsTrashFill className="margin-bottom-3"  title="Delete VCF file" />
						       	</button>
            </div>;
          }
    }
  ];
  
  render(){
    [selectedpage, selectedrowid] = calculateTablePage (this.node, this.props.selectedrowid, "vcfid");

    const CVFFilesPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.searchResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { backgroundColor: "#c8e6c9" },
      selected: selectedrowid,
    };

    return (

      <div>
        <div>
            <PaginationProvider pagination={paginationFactory(CVFFilesPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField="vcfid"
                    ref={ n => this.node = n }
                    data={this.props.searchResults}
                    columns={this.VCFFilesColumns}
                    selectRow={ selectedRow }
                    {...paginationTableProps}
                    
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
        </div>
    </div>
    );
  }
};