import * as React from "react";
import queryString from 'query-string';
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import {removeOrcidCode} from './utils/gapCommon';
import { RELEASE_VER} from '../App.constants';

export default class About extends React.Component<Transition> {

  render() {
    removeOrcidCode("about", this.props);
    return (
      <div className="gap-page-content">
        <div className="container">
          <h3>About</h3>
          <hr />
          <p><b>GAP (Genomic Analysis Portal) version {RELEASE_VER}</b></p>

          <p>The TB Portals Genomic Analysis Portal (GAP) allows researchers to perform in depth exploration of genomic data from the TB Portals Consortium and from other tuberculosis projects. GAP incorporates numerous open source tools for the analysis of TB related genomic information.</p>

          <p><b><span style={{ color: 'red' }}>Disclaimer:</span> The GAP is intended for research purposes only and is not intended for use in clinical practice or clinical decision making.</b></p>

        </div>
      </div>
    );
  }
}
