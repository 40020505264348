import * as React from "react";
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import { ProgressBar } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import queryString from 'query-string';
import axios from "axios";
import API_URL from '../../../services/Enviroment';
import RPTable from './RPTable';
import UserSession from "../../../services/UserSession";
import PredictionResult from '../../../domain/PredictionResult';
import GoogleFileType from '../../../domain/GoogleFileType';
import { CSVLink } from "react-csv";
import {removeOrcidCode} from '../../utils/gapCommon';
import './ResistancePrediction.css'
import NCBISampleInfor from "../../../domain/NCBISampleInfor";
import ENASampleInfor from "../../../domain/ENASampleInfor";
import e from "express";

let usersession = UserSession.getUserSession();
type predictionType ={
  NTMProfiler: boolean,
}

type csvHeadType = {
  label: string,
  key: string,
}

type RPState = {
  blocking: boolean;
  errors: string;
  fastq1File?: File;
  fastq2File?: File;
  predictionTypes: predictionType;
  progress1: number;
  progress2: number;
  hascomparedresult: boolean;
  TbSessions: Array<PredictionResult>;
  fastqtype: string;
  ncbisample: string;
  samplePlaceholder:string;
  googlefiles1 : Array<GoogleFileType>;
  googlefiles2 : Array<GoogleFileType>;
  googlebacktofolder1: boolean;
  googlebacktofolder2: boolean;
  googlefileseleted1?: GoogleFileType;
  googlefileseleted2?: GoogleFileType;
} 

export default class NTMResistancePrediction extends React.Component<Transition, RPState> {
  usersession = UserSession.getUserSession();
  timer : NodeJS.Timer | undefined = undefined;
  comparedcols = Array<csvHeadType>();
  comparedrows = Array<Array<string>>();
  DownloadData = Array();
  queries = queryString.parse(window.location.search);
  ncbiSamplesInfo = Array<NCBISampleInfor>();
  enaSamplesInfo = Array<ENASampleInfor>();
  ncbiSampleplaceholder = "Input NCBI sample ID, start with SRR";
  enaSampleplaceholder= "Input ENA sample ID, start with DRR";
  
  constructor(props: Transition) {
    super(props);

    if(this.queries.googleaccesscode != undefined){
      this.state = {
        blocking: false,
        fastq1File: undefined,
        fastq2File: undefined,
        predictionTypes: { NTMProfiler: true},
        progress1:0,
        progress2:0,
        fastqtype: "g",
        ncbisample: "",
        samplePlaceholder:"",
        hascomparedresult: false,
        errors: "",
        TbSessions: [],
        googlefiles1: [],
        googlefiles2: [],
        googlebacktofolder1: false,
        googlebacktofolder2: false,
        googlefileseleted1: undefined,
        googlefileseleted2: undefined
      };
    }
    else{
    this.state = {
        blocking: false,
        fastq1File: undefined,
        fastq2File: undefined,
        predictionTypes: { NTMProfiler: true},
        progress1:0,
        progress2:0,
        fastqtype: "l",
        ncbisample: "",
        samplePlaceholder:"",
        hascomparedresult: false,
        errors: "",
        TbSessions: [],
        googlefiles1: [],
        googlefiles2: [],
        googlebacktofolder1: false,
        googlebacktofolder2: false,
        googlefileseleted1: undefined,
        googlefileseleted2: undefined
      };
    }

    this.loadgooglefiles = this.loadgooglefiles.bind(this);
  }

  onFastq1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    var fileList = event.target.files;
    if (!fileList) return;
    this.setState ({fastq1File: fileList[0]});
}

  onFastq2Change = (event: React.ChangeEvent <HTMLInputElement>) => {
      const fileList = event.target.files;
      if (!fileList) return;
      this.setState({fastq2File: fileList[0]})
  }

  onNTMProfilerChange =  (event: React.FormEvent <HTMLInputElement>) => {
    var predictionTypes = this.state.predictionTypes;
    predictionTypes.NTMProfiler = !this.state.predictionTypes.NTMProfiler;
    this.setState({predictionTypes: predictionTypes});
  }

  excuteTBCommand = () => {
    if (this.state.fastqtype === "l" ){
      if(this.checkfilename(this.state.fastq1File?.name, this.state.fastq2File?.name) == false) {
        alert("Please select fastq or fastq.gz file and matching files for forward and reverse reads.");
        return
      }
    }
    this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
    this.enaSamplesInfo = new Array<ENASampleInfor>();

    this.setState({ errors: ""});

    let url = API_URL + 'prediction/checkWorkerLimitation';
    axios.get(url)
        .then(response => {
          if (this.state.fastqtype === "l" ){
            this.localfileprocess();
          }
          else if (this.state.fastqtype === "n" ){
            this.processNCBISample();
          }
          else if (this.state.fastqtype === "e" ){
            this.processENASample();
          }
          else if(this.state.fastqtype=='g'){
            this.googledrivefileprocess();
          }
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
          this.setState({ errors: error.response.data});
        });
  }


  processENASample =() => {
    var stateJson = {
      blocking: true,
      errors:""
    } as RPState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/processENASample';
    axios.post(url, { "sampleID": this.state.ncbisample, "PredictionType": this.state.predictionTypes})
    .then(response => {
      this.enaSamplesInfo = response.data;
      stateJson.blocking=false;
      this.loadResults();
      this.setState(stateJson);
    })
    .catch(error => {
      stateJson.blocking = false;
      if(error.response.status == 400){
        stateJson.errors = error.response.data;
        this.setState(stateJson);
      }
      else {
        stateJson.errors = "Fetch NCBI Fastq file failed, Error code:" + error.response.status  + ". Error:" + error;
        this.setState(stateJson);
      }
      
    });
  }


  processNCBISample =() => {
    var stateJson = {
      blocking: true,
      errors:""
    } as RPState;
    this.setState(stateJson);
    
    let url = API_URL + 'prediction/processNCBISample';
    axios.post(url, { "sampleID": this.state.ncbisample, "PredictionType": this.state.predictionTypes})
    .then(response => {
      this.ncbiSamplesInfo = response.data;
      stateJson.blocking=false;
      this.setState(stateJson);
      this.loadResults();
    })
    .catch(error => {
      stateJson.blocking = false;
      this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
      if(error.response.status == 400){
        stateJson.errors = error.response.data;
        this.setState(stateJson);
      }
      else {
        stateJson.errors = "Fetch NCBI Fastq file failed, Error code:" + error.response.status  + ". Error:" + error;
        this.setState(stateJson);
      }
      
    });
  }

  googledrivefileprocess() {
    let url = API_URL + 'prediction/googledriveupload';

    var upload_data = {
      'access_token': sessionStorage.getItem("access_token"),
      'refresh_token': sessionStorage.getItem("refresh_token"),
      'fastq1id': this.state.googlefileseleted1?.id,
      'fastq2id': this.state.googlefileseleted2?.id,
      'fastq1': this.state.googlefileseleted1?.name,
      'fastq2': this.state.googlefileseleted2?.name,
      "PredictionType": this.state.predictionTypes
    };

    this.setState ({blocking:true});

    axios.post(url, upload_data)
      .then(response => {
        this.loadResults();
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
      });
  }

  localfileprocess(){
    if (this.checkfilename(this.state.fastq1File?.name, this.state.fastq2File?.name) == false) {
      alert("Please select fastq or fastq.gz file and matching files for forward and reverse reads.");
      return
    }

    if (this.state.fastq1File == undefined) return;
    var uploadfile1 = this.state.fastq1File;

    this.setState({progress1:0, progress2:0});

    let url = API_URL + 'prediction/uploadFastqFile';
    let formData = new FormData()
    formData.append("file", uploadfile1);
    axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        this.setState({ progress1: (Math.round((100 * data.loaded) / data.total)) });
      },
    })
      .then(response => {
        var uploadfilename1 = response.data.filename;
        var foldername = response.data.foldername;
        this.uploadfile2(uploadfilename1, foldername);
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState({ errors: 'There was an error!' + error.message });
      });
  }

  uploadfile2 = (uploadfilename1: string, foldername:string ) => {
    if (this.state.fastq2File == undefined) return;
    var uploadfile2 = this.state.fastq2File;
    let url = API_URL + 'prediction/uploadFastqFile';
    let formData = new FormData()
    formData.append("file", uploadfile2);
    axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        this.setState({ progress2: (Math.round((100 * data.loaded) / data.total)) });
      },
    })
    .then(response => {  //uploaded
      var uploadfilename2 = response.data.filename;
      this.getPredictionResults(uploadfilename1, uploadfilename2);
    })
    .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState({ errors: 'There was an error!' + error.message });
    });
  }

  getPredictionResults = (fastq1: string, fastq2: string) => {
    let url = API_URL + 'prediction/PredictionResult';
    axios.post(url, { "fastq1": fastq1, "fastq2": fastq2, "PredictionType": this.state.predictionTypes })
      .then(response => {
        this.loadResults();
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
      });
  }

  loadResults =() =>{
    this.stopInterval();

    let url = API_URL + 'prediction/LoadResults';
    axios.post(url, { "predictionSession": this.state.TbSessions })
      .then(response => {
        var tbsession = response.data.filter(function (data: PredictionResult) {
          return (data.predictionType == 'NTMProfiler');
        });
        this.setState({ TbSessions: tbsession, blocking:false })
        this.checkresult();
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState({ errors: 'There was an error!' + error.message, blocking: false });
      });
  }

  checkresult = () =>{
		if(this.state.TbSessions.length > 0){
			var hasresult = true; 

			 this.state.TbSessions.forEach((item) => {
				 if(item.status === "submitted" || item.status === "processing" || item.status === "cancelled"){
					 hasresult = false;
				 }
			 });
			 
			 if(hasresult && this.timer != undefined){
				 this.stopInterval();
			 }
		
			if(!hasresult && this.timer == undefined){
        	this.startInterval();
			}
		}
	}

  deleteResult = (id:String) =>{
    let url = API_URL + 'prediction/deleteResult';
    axios.post(url, {"predictionId":id})
        .then(response => {
          var tbsession = this.state.TbSessions;
          var newtbsession = tbsession.filter(function(data : PredictionResult){
            return (data.predictionid !== id);
          });

          this.setState({TbSessions: newtbsession})
          
          this.loadResults();
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 	
  }

  cancelProcessing =(id:String) =>{
    let url = API_URL + 'prediction/cancelProcessing';
    axios.post(url, {"predictionId":id})
        .then(response => {
          var tbsession = this.state.TbSessions;
          var newtbsession = tbsession.filter(function(data : PredictionResult){
            return (data.predictionid !== id);
          });

          this.setState({TbSessions: newtbsession})

          this.loadResults();
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 	
  }

  comparereport =(selectedresult: Array<PredictionResult>) =>{	
		this.stopInterval();
    var stateJson = {
      blocking: true,
      hascomparedresult: false,
      errors:""
    } as RPState;

    this.setState(stateJson);
		
		this.comparedcols=[];
		this.comparedrows=[];
    this.DownloadData=[];

    let url = API_URL + 'prediction/compareresult';
   
    axios.post(url, { sessiondata: selectedresult })
      .then(response => {
        var comparedresult = response.data;
        if(comparedresult.row){
          stateJson.hascomparedresult=true;
          var cols = comparedresult.col as Array<string>;
          this.comparedcols = Object.entries(cols).map(([key, value], i) => {
            return { "label":value,  "key": "col" + key}
          });
          this.comparedrows=comparedresult.row
          var rows = comparedresult.row as Array<string>;
          this.DownloadData = Object.entries(rows).map(([ki, data], i) => {
            var rowJson: { [key: string]: string }={};
            Object.entries(data).map(([key1, value], i) => {
              rowJson['col' + key1] = value;
            });
            return rowJson;
          });
        }

        stateJson.blocking = false;
        this.setState(stateJson);

        this.checkresult();
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState ({errors: 'There was an error!' + error.message, blocking:false});

      });
  }

  checkfilename = (name1?: string, name2?: string) => {
    var valid = false;
    var diff1 = "";
    var diff2 = "";

    if (name1 == undefined || name2 == undefined) return false;

    if (name1.length == name2.length) {
      var count = 0;
      for (var i = 0; i < name1.length; i++) {
        if (name1[i] != name2[i]) {
          count++;
          diff1 = name1[i];
          diff2 = name2[i];
        }
      }

      if (count == 1) {
        valid = true;
      }
    }

    if (!(name1.endsWith("fastq") || name1.endsWith("fastq.gz")) ||
      !(name2.endsWith("fastq") || name2.endsWith("fastq.gz"))) {
      valid = false;
    }

    return valid;
  }

  startInterval =() =>{
		this.timer = setInterval(() => {
			if(this.state.TbSessions.length > 0){
				this.loadResults();
			}
		}, 4000);
	}
	
  stopInterval = () => {
    if (this.timer != undefined) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  onUploadTypeChange = (event: React.FormEvent<HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    this.setState({fastqtype:target.value});

    var access_token = sessionStorage.getItem("access_token");

    if(target.value == 'g' && (access_token === null || access_token === '')){
      let url = API_URL + 'clouddrive/googledriveauthorize';
   
      axios.get(url)
        .then(response => {
          sessionStorage.setItem("orginState", location.pathname);
          var authurl = response.data.map.authurl;
          window.open(authurl, '_self');
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
        });
    }
  }

  loadgooglefiles =(folderid: string, isfastq1: boolean, isfastq2: boolean) =>{
    var access_token = sessionStorage.getItem("access_token");
    var refresh_token = sessionStorage.getItem("refresh_token");

    if(access_token === null || access_token === '') return;

    var google_token = {
      'access_token': sessionStorage.getItem("access_token"),
      'refresh_token': sessionStorage.getItem("refresh_token"),
      'folder_id': folderid
    };

    var backtofolder = true;
    if(folderid == '') backtofolder = false;

    let url = API_URL + 'clouddrive/googledrivefiles';
    axios.post(url, google_token)
      .then(response => {
        var googlefiles = response.data;
        if(isfastq1 && isfastq2){
          this.setState({"googlefiles1": googlefiles, "googlefiles2": googlefiles,  "googlebacktofolder1": backtofolder, "googlebacktofolder2": backtofolder});
        }
        else if(isfastq1){
          this.setState({"googlefiles1": googlefiles,  "googlebacktofolder1": backtofolder});
        }
        else if(isfastq2){
          this.setState({"googlefiles2": googlefiles,  "googlebacktofolder2": backtofolder});
        }
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState ({errors: 'There was an error!' + error.message, blocking:false});
      });
  }

  googledriveback = (isfasq1: boolean, isfasq2: boolean) =>{
    this.loadgooglefiles('', isfasq1, isfasq2);
  }

  selectedgooglefile = (file: GoogleFileType, isfasq1: boolean, isfasq2: boolean) => {
    if(isfasq1){
      this.setState({googlefileseleted1: file});
    }
    if(isfasq2){
      this.setState({googlefileseleted2: file});
    }
  }

  processGoogleDriveCallBack(){
    if(this.queries.googleaccesscode != undefined){
      var code = this.queries.googleaccesscode;
		  let url = API_URL + 'clouddrive/getGoogelToken';
   
      axios.post(url, { code: code })
        .then(response => {
          var tokenJson = response.data.map;
          if (tokenJson.access_token == '') {
            sessionStorage.setItem('access_token', "");
            sessionStorage.setItem('refresh_token', "");
          }
          else {
            sessionStorage.setItem('access_token', tokenJson.access_token);
            sessionStorage.setItem('refresh_token', tokenJson.refresh_token);
            this.loadgooglefiles('', true, true);
          }
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
        });
      var stateService = this.props.router.stateService
      stateService.go("ntmresistancePrediction", { googleaccesscode: '' });
    }
    else{
      this.loadgooglefiles('', true, true);
    }
  }

  onFastqtypeChange =  (event: React.FormEvent<HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    var holderString = target.value === "n" ? this.ncbiSampleplaceholder:this.enaSampleplaceholder;
    this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
    this.enaSamplesInfo = new Array<ENASampleInfor>();
    this.setState({fastqtype:target.value, samplePlaceholder: holderString, ncbisample: ""}); 
  }

  onEnterncbiSample = (event: React.FormEvent <HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    this.setState ({ncbisample: target.value});
  }

  componentWillUnmount(){
    this.stopInterval();
  }

  componentDidMount(){
    this.loadResults();
    this.processGoogleDriveCallBack();
  }

  checkstatus =() =>{
    const queries = queryString.parse(window.location.search);
    if(Object.keys(queries).length > 0){
      removeOrcidCode("ntmresistancePrediction", this.props);
    }
    else{
      this.usersession = JSON.parse(sessionStorage.userSession);
      if(!this.usersession.isverified){
        let transition = this.props;
        const $state = transition.router.stateService;
        $state.go('resistance');
      } 
    }
  }

  render() {
    this.checkstatus();
   // removeOrcidCode("ntmresistancePrediction", this.props);
    
    this.usersession = JSON.parse(sessionStorage.userSession);
    return (
      <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
        <div className="gap-page-content">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className='breadcrumb-item'>
                  <UISref to="home"><a>Home</a></UISref>
                </li>
                <li className='breadcrumb-item'>
                  <UISref to="resistance"><a>Resistance</a></UISref>
                </li>
                <li className="breadcrumb-item active" aria-current="page">NTM Resistance Prediction</li>
              </ol>
            </nav>
            <h1>NTM Resistance Prediction</h1>
            <div className="row">
              <span className="redcolor">{this.state.errors}</span>
            </div>

            <div>
              The algorithms listed below will predict sensitivity or resistance to NTM drugs using whole genome sequencing data from the NTM  pathogen. Enter an SRA id or upload the fastq files containing the sequencing data for a sample and then select one or more algorithms to run.   Results from different algorithms can be compared in a consolidated report.
            </div>
            <hr />
            <form name="tbproilferForm">
              {/* <div className="row margin-bottom-10" >
                <div className="col-md-6">
                  <input id="cb_sample" type="radio" name="distancetype" value="l" checked={this.state.fastqtype === "l"}
                    onChange={this.onUploadTypeChange} />
                  <span className="margin-left-10" >
                    <label htmlFor="cb_sample">Local Upload</label>
                  </span>
                </div>
                <div className="col-md-6">
                  <input id="cb_list" type="radio" name="distancetype" value="g" checked={this.state.fastqtype === "g"}
                    onChange={this.onUploadTypeChange} />
                  <span className="margin-left-10" >
                    <label htmlFor="cb_list">Google Drive</label>
                  </span>
                </div>
              </div> */}
              <div className="row margin-top-10" >
                <div className="col-md-6">
                  <input id="cb_local" type="radio" name="fastqtype" value="l" checked={this.state.fastqtype === "l"}
                    onChange={this.onFastqtypeChange} />
                  <span className="margin-left-10" >
                    <label htmlFor="cb_sample">Local Upload</label>
                  </span>
                </div>
                {this.usersession.isverified &&
                  <div className="col-md-6">
                    <input id="cb_ncbi" type="radio" name="fastqtype" value="n" checked={this.state.fastqtype === "n"}
                      onChange={this.onFastqtypeChange} />
                    <span className="margin-right-20 margin-left-5" >
                      <label htmlFor="cb_list">NCBI</label>
                    </span>
                    <input id="cb_ncbi" type="radio" name="fastqtype" value="e" checked={this.state.fastqtype === "e"}
                      onChange={this.onFastqtypeChange} />
                    <span className="margin-right-20 margin-left-5" >
                      <label htmlFor="cb_list">ENA</label>
                    </span>
                  </div>
                  
                }
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <fieldset className="custom_fieldset" style={{ "height": "250px" }} disabled={this.state.fastqtype != 'l'}>
                    <div className="form-group margin-top-10">
                      <label htmlFor="Fastq1">Fastq1</label>
                      <input type="file" className="form-control" onChange={this.onFastq1Change} accept=".gz" />
                      <p className="help-block">Fastq.gz file containing forward reads</p>
                      <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} />
                    </div>

                    <div className="form-group margin-top-10">
                      <label htmlFor="Fastq2">Fastq2</label>
                      <input type="file" className="form-control" onChange={this.onFastq2Change} accept=".gz" />
                      <p className="help-block">Fastq.gz file containing reverse reads</p>
                      <ProgressBar now={this.state.progress2} label={`${this.state.progress2}%`} />
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6">
                  <fieldset className="custom_fieldset" style={{ "height": "250px" }} disabled={this.state.fastqtype === 'l'}>
                    <div className="form-group margin-top-10">
                      <label htmlFor="ncbisample">Sample ID</label>
                      <input type="text" className="form-control" id="ncbisample" name="input" value={this.state.ncbisample}
                        onChange={this.onEnterncbiSample}
                        placeholder={this.state.samplePlaceholder} width="60px" required
                      />
                    </div>
                    {this.ncbiSamplesInfo.length > 0 &&
                        <div className="ncbienainfo">
                          {this.ncbiSamplesInfo.map((info) =>(
                            <div className="margin-top-8">
                              <div className="col-md-10">
                                <label>acc:&nbsp;</label> {info.acc}
                              </div>
                              <div className="col-md-10">
                                <label>size:&nbsp;</label> {info.size}
                              </div>
                              <div className="col-md-10">
                                <label>SEQ:&nbsp;</label>{info.SEQ}
                              </div>
                              <div className="col-md-10">
                                <label>platf:&nbsp;</label>{info.platf}
                              </div>
                              <div>
                                <label>FMT:&nbsp;</label>{info.FMT}
                              </div>
                            </div>
                          ))}
                      </div>
                    }

                    {this.enaSamplesInfo.length > 0 &&
                        <div className="ncbienainfo">
                          {this.enaSamplesInfo.map((info) =>(
                            <div className="margin-top-8">
                               <div className="col-md-10">
                                <label>acc:&nbsp;</label> {info.accession}
                              </div>
                              <div className="col-md-10">
                                <label>title:&nbsp; </label>{info.title}
                              </div>
                              <div className="col-md-10">
                                <label>description:&nbsp; </label>{info.description}
                              </div>
                              <div className="col-md-10">
                                <label>dataType:&nbsp; </label>{info.dataType}
                              </div>
                              <div>
                                <label>status:&nbsp; </label>{info.statusDescription}
                              </div>
                              <div>
                                <label>fastq1 size:&nbsp; </label>{info.fastq1}
                              </div>
                              <div>
                                <label>fastq2 size:&nbsp; </label>{info.fastq2}
                              </div>
                          </div>
                          ))}
                      </div>
                    }
                  </fieldset>
                </div>
              </div>
              
              <hr />
              <h1>Prediction Algorithm</h1>
              <table>
                <tbody>
                  <tr>
                    <td width="80px">
                      <label htmlFor="cb_kvarq">NTMProfiler</label>
                    </td>
                    <td width="380px">
                      <a href="https://github.com/jodyphelan/NTM-Profiler" target="_blank">https://github.com/jodyphelan/NTM-Profiler</a>
                    </td>
                    <td>
                      <a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=25297886" target="_blank">publication</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <button id="tbproilferFormdata" type="button" className="btn btn-primary"
                disabled={(this.state.fastqtype === 'l' && 
                          (this.state.fastq1File === undefined || this.state.fastq2File === undefined)) ||
                          (this.state.fastqtype ==="n" && (this.state.ncbisample === "")) ||
                          (this.state.fastqtype ==="e" && (this.state.ncbisample === "")) }
               
                onClick={this.excuteTBCommand}>Execute</button>
            </form>
            <br></br>
            {this.state.TbSessions.length >0 &&
              <div>
                <RPTable 
                  searchResults={this.state.TbSessions} 
                  hascompare={false} 
                  resistanceType={"NTM"} 
                  deleteResult = {(id:String) => this.deleteResult(id)}
                  cancelProcessing = {(id:String) => this.cancelProcessing(id)}/>
              </div> 
            }
           
          </div>

        </div>
      </BlockUi>
    );
  }
}
