
import React from "react";
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';
import axios from "axios";
import UserSession from '../../../../services/UserSession';
import Modal from 'react-bootstrap/Modal'
import { UISref, Transition} from "@uirouter/react";
import API_URL from '../../../../services/Enviroment';
import NCBIReference from "../../../../domain/NCBIReference"
import NcbiSViewer from '../../../Genome/NcbiSViewer';
import ReferenceTable from "./ReferenceTable";
import TrackDataTable from "./TrackDataTable";

import { ProgressBar } from 'react-bootstrap';
import NCBITrackData from "../../../../domain/NCBITrackData";

type referenceState = {
    blocking: boolean,
    blockmessage: string,
    resultfound: boolean,
    showReferenceModal: boolean,
    showTrackModal: boolean,
    SelectedRef?: NCBIReference,
    SelectedTrack?: NCBITrackData,
    trackpublicurl: string,
    trackuploadFile?: File,
    progress1: number,
    viewtrackurl: string,
    reloadSview: number;
    errors: string,
    haserror: boolean,
    hasmodalerror: boolean,
    modalerrors: string,
    modaleblocking: boolean,
    trackDatas : Array<NCBITrackData>
}

export default class MgmReference extends React.Component <Transition, referenceState> {
    usersession = UserSession.getUserSession();
    allreferences=new Array();
    selectedRowid="";
    //trackDatas = new Array<NCBITrackData>();

    constructor(props: Transition) {
        super(props);
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isadminuser){
            let transition = props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.state = {
            blocking: false,
            blockmessage: "Loading",
            SelectedRef: undefined,
            resultfound: false,
            haserror: false,
            hasmodalerror: false,
            showReferenceModal: false,
            showTrackModal: false,
            trackpublicurl: "",
            trackuploadFile: undefined,
            progress1: 0,
            reloadSview: 1,
            viewtrackurl:"",
            errors: "",
            modalerrors:"",
            modaleblocking: false,
            trackDatas: [],
        };
    }

    LoadReference =(stateJson: referenceState) =>{
        let url = API_URL +'admin/getReferences';
        axios.get(url)
            .then(response => {
                this.allreferences = response.data;
                stateJson.blocking = false;
                stateJson.resultfound = true;
                stateJson.showReferenceModal = false;
                stateJson.SelectedRef=undefined;
                stateJson.viewtrackurl = "";
                this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false});
            });
    }

    onEnterReferenceName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var selectedref = this.state.SelectedRef;
        if(selectedref !== undefined){   
            selectedref.referenceName = target.value;
            this.setState ({SelectedRef:  selectedref});
        }
    }

    onEnterTrackname = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var selecttrack = this.state.SelectedTrack;
        if(selecttrack !== undefined){   
            selecttrack.trackname = target.value;
            this.setState ({SelectedTrack:  selecttrack});
        }
    }

    onEnterNCBIEmbedded = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var selectedref = this.state.SelectedRef;
        if(selectedref !== undefined){   
            selectedref.NCBI_Embedded = target.value;
            this.setState ({SelectedRef:  selectedref});
        }
    }

    onCloseReference =() =>{
        var stateJson = {
            blocking:true,
            showReferenceModal: false,
            SelectedRef: undefined
        } as referenceState;
        this.setState (stateJson);

        this.LoadReference(stateJson);
    }

    onCloseTrack =() =>{
        var stateJson = {
            blocking:false,
            modaleblocking: false,
            errors: "",
            modalerrors:"",
            showReferenceModal: false,
            showTrackModal: false,

        } as referenceState;
        this.setState (stateJson);
    }

    saveReference=() =>{
        if(this.state.SelectedRef !== undefined){
            this.updateReference(this.state.SelectedRef);
        }
    }

    updateReference = (row: NCBIReference) =>{
        var stateJson = {
            blocking:true,
            resultfound: false,
            blockmessage: "Update ...."
        } as referenceState;
        this.setState (stateJson);

        let url = API_URL +'admin/updateReference';
        axios.post(url,{Reference: row})
            .then(response => {
                this.LoadReference(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                if(this.state.showReferenceModal){
                    stateJson.modaleblocking=false;
                    stateJson.hasmodalerror = true;
                    stateJson.modalerrors=  error.message;
                }
                else{
                    stateJson.blocking=false;
                    stateJson.haserror = true;
                    stateJson.errors=  error.message;
                }
                this.setState(stateJson);
            });
    }

    onEditReference =(row: NCBIReference) =>{
        this.setState ({SelectedRef: row, 
                        showReferenceModal: true, 
                        blocking: false,
                        modaleblocking: false,
                        errors: "",
                        modalerrors: "",
                        haserror: false,
                        hasmodalerror: false,
                        });
    }
    
    onDeleteReference =(row: NCBIReference)=>{
        var stateJson = {
            blocking:true,
            showReferenceModal: false,
        } as referenceState;
        this.setState (stateJson);

        let url = API_URL +'admin/deleteReference';
        axios.post(url,{Reference: row})
            .then(response => {
                stateJson.haserror = true;
                stateJson.errors = response.data;
                this.LoadReference(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({blocking: false, errors: error.message, haserror: true});
            });
    }

    onDeletetrackData  =(row: NCBITrackData)=>{
        var stateJson = {
            modaleblocking: true,
        } as referenceState;
        this.setState (stateJson);

        let url = API_URL +'admin/deletetrackData';
        axios.post(url,{trackdata: row})
            .then(response => {
                var selectedref = this.state.SelectedRef;
                if(selectedref != undefined){

                    var trackdatas = response.data as Array<NCBITrackData>;
                    selectedref.trackdatas = trackdatas;
                    stateJson.trackDatas = trackdatas;

                    stateJson.blocking = false;
                    stateJson.SelectedRef = selectedref;
                    stateJson.viewtrackurl = "";
                    this.setState(stateJson);


                    //this.trackDatas = response.data;
                    //selectedref.trackdatas = this.trackDatas;
                    //this.setState({blocking: false, SelectedRef: selectedref, viewtrackurl: ""});
                }
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({errors: error.message, haserror:true});
            });
    }
    
    onViewReference=(row: NCBIReference)=>{
        var reload = this.state.reloadSview + 1;
        this.setState ({SelectedRef: row, 
            showReferenceModal: false, 
            showTrackModal: false,
            reloadSview: reload,
            trackDatas:  row.trackdatas
            });
        //this.trackDatas = row.trackdatas;
    }

    onViewtrackData = (row: NCBITrackData)=>{
        var reload = this.state.reloadSview + 1;
        this.setState({viewtrackurl: row.trackdataurl, reloadSview: reload});
    }

    onAddnewRef =() =>{
        var row = {referenceId:"0"} as NCBIReference;
        this.setState ({
            SelectedRef: row, 
            blocking:false,
            modaleblocking:false,
            haserror:false,
            hasmodalerror:false,
            errors:"",
            modalerrors:"",
            showReferenceModal: true});
    }

    onAddtrackData =() =>{
        var row = {trackId:"0", 
                   tracktype:"local", 
                   ispublic: false,
                   referenceId: this.state.SelectedRef?.referenceId
                  } as NCBITrackData;
        this.setState ({
            SelectedTrack: row, 
            blocking:false,
            modaleblocking:false,
            haserror:false,
            hasmodalerror:false,
            errors:"",
            modalerrors:"",
            showReferenceModal: false,
            trackpublicurl:"",
            trackuploadFile: undefined,
            showTrackModal: true});
    }

    onEditTrackData = (row: NCBITrackData)=>{
        var trackpublicurl = "";
        if(row.tracktype == "remote"){
            trackpublicurl = row.trackdataurl;
        }

        this.setState ({SelectedTrack: row, 
            showTrackModal: true,
            showReferenceModal: false,
            blocking: false,
            modaleblocking: false,
            errors: "",
            modalerrors: "",
            trackpublicurl: trackpublicurl,
            haserror: false,
            hasmodalerror: false});
    }

    onUpdateTrackDataRow = (row : NCBITrackData) =>{
        let url = API_URL + 'admin/updatetrackdata';
        axios.post(url, { trackdata: row })
            .then(response => {
                this.selectedRowid = row.trackId;
                var trackdata = response.data as Array<NCBITrackData>;
                this.setState({trackDatas: trackdata});
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState({ blocking: false, errors: error.message });
            });
    }

    onSaveTrackData = () =>{
        if(this.state.SelectedTrack !== undefined){
            this.onUpdateTrackData(this.state.SelectedTrack);
        }
    }
 
    onUpdateTrackData = (row: NCBITrackData) =>{
        if(this.state.SelectedRef==undefined || 
           this.state.SelectedRef.referenceId === "0"){
            return;
        }

        var stateJson = {
            modaleblocking: true,
            hasmodalerror: false,
            modalerrors: "",
        } as referenceState;

        if(!this.state.showTrackModal){
            stateJson = {
                blocking: true,
                haserror: false,
                errors: "",
            } as referenceState;
        }

        this.setState(stateJson);

        var trackdata = JSON.stringify(row);
        let formData = new FormData();

        formData.append("trackdata", trackdata);

        if(this.state.showTrackModal){
            if(row.tracktype === "local"){
                if(this.state.trackuploadFile != undefined){
                    formData.append("file", this.state.trackuploadFile);
                    formData.append("publicurl", window.location.origin + "/publicdata/" + this.state.trackuploadFile.name);
                }
            }
            else{
                formData.append("publicurl", this.state.trackpublicurl);
            }
        }
        else{
            formData.append("publicurl", row.trackdataurl);
        }

        let url = API_URL + 'admin/savetrackdata';
        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(response => {  
            var selectedref = this.state.SelectedRef;
            if(selectedref != undefined){
                var trackdatas = response.data as Array<NCBITrackData>;
                selectedref.trackdatas = trackdatas;
             
                this.setState({
                    blocking: false, 
                    modaleblocking: false,
                    hasmodalerror:false,
                    haserror: true,
                    modalerrors: "",
                    SelectedRef: selectedref,
                    showTrackModal: false,
                    errors: "Track data has been saved.",
                    trackDatas: trackdatas
                    });
            }
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            
            if(this.state.showTrackModal){
                stateJson.modaleblocking = false;
                stateJson.hasmodalerror = true;
                stateJson.modalerrors =  error.message;
            }
            else{
                stateJson.blocking = false;
                stateJson.haserror = true;
                stateJson.errors =  error.message;
            }
            this.setState(stateJson);
        });
    }

    onTracktypeChange = (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var selectedtrack = this.state.SelectedTrack;
        if(selectedtrack !== undefined){   
            selectedtrack.tracktype = target.value;
            this.setState({SelectedTrack:selectedtrack});
        }
    }

    onEntertrackDescription=(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var target = event.target as HTMLTextAreaElement;
        var selectedtrack = this.state.SelectedTrack;
        if(selectedtrack !== undefined){   
            selectedtrack.description = target.value;
            this.setState({SelectedTrack:selectedtrack});
        }
    }

    onEnterPublicUrl=(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        var target = event.target as HTMLTextAreaElement;
        this.setState({trackpublicurl:target.value});
    }

    ontrackFileUpload = (event: React.ChangeEvent <HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        this.setState({trackuploadFile: fileList[0]})
    }

    componentDidMount(){
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            haserror: false,
            errors:"",
        } as referenceState;
        this.setState(stateJson);

        this.LoadReference(stateJson);
    }

    checkstatus =() =>{
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isadminuser){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }
    }
    
    render() {
        this.checkstatus();
        return (
            <div>
                <div className="gap-page-content">
                    <BlockUi tag="div" blocking={this.state.blocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                        <div className="container">
                            <h1>NCBI Reference Management</h1>
                            <hr></hr>
                            {this.state.haserror &&
                                <div className="row redcolor">
                                    <p>{this.state.errors}</p>
                                </div>
                            }

                            <div className="row margin-bottom-10">
                                <div className="button-right" >
                                    <button id="addnewvcf" type="button" className="btn btn-primary" onClick={this.onAddnewRef}>Add new Reference</button>
                                </div>
                            </div>
                            <form name="referenceForm">
                                {this.state.resultfound &&
                                    <ReferenceTable searchResults={this.allreferences}
                                        onViewReference={(row: NCBIReference) => this.onViewReference(row)}
                                        onEditReference={(row: NCBIReference) => this.onEditReference(row)}
                                        updateReference={(row: NCBIReference) => this.updateReference(row)}
                                        onDeleteReference={(row: NCBIReference) => this.onDeleteReference(row)} >
                                    </ReferenceTable>
                                }
                            </form>
                           
                            {!this.state.showReferenceModal && this.state.SelectedRef !== undefined &&
                                <div className="margin-top-30">
                                    <div className="row">
                                        {this.state.SelectedRef.trackdatas.length > 0 &&
                                            <div className="col-md-5 padding-top-15">
                                                <h5>NCBI track data list for {this.state.SelectedRef.referenceName}</h5>
                                            </div>
                                        }
                                        <div className="col-md-7 button-right">
                                            <button id="addtrackdata" type="button" className="btn btn-primary"
                                                onClick={this.onAddtrackData}>Add Custom track Data
                                            </button>
                                        </div>
                                    </div>  
                                    {this.state.SelectedRef.trackdatas.length > 0 &&
                                        <div className="row">
                                            <div>
                                                <TrackDataTable trackdatas={this.state.trackDatas}
                                                    selectedrowid={this.selectedRowid}
                                                    onDeletetrackData={(row: NCBITrackData) => this.onDeletetrackData(row)}
                                                    onEditTrackData={(row: NCBITrackData) => this.onEditTrackData(row)}
                                                    onUpdateRowData={(row: NCBITrackData) => this.onUpdateTrackDataRow(row)}
                                                    onViewTrackData={(row: NCBITrackData) => this.onViewtrackData(row)} >
                                                </TrackDataTable>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <h5>Genome Browser</h5>
                                    </div>
                                    <div className="row">
                                        <NcbiSViewer NCBIEmbeddedParameter={this.state.SelectedRef?.NCBI_Embedded} reloadSview={this.state.reloadSview} trackurl={this.state.viewtrackurl} position='' />
                                    </div>
                                </div>
                            }
                        </div>
                    </BlockUi>
                    <Modal show={this.state.showReferenceModal}
                        size="lg"
                        animation={false}
                        backdrop="static"
                        keyboard={false}>
                        <BlockUi tag="div" blocking={this.state.modaleblocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                            <Modal.Header>
                                <Modal.Title>
                                    <div>
                                        <h2 className="lead intro">Edit a Reference </h2>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.hasmodalerror && 
                                    <div className="row redcolor">
                                        <p>{this.state.modalerrors}</p>
                                    </div>
                                }
                                <div className="row">
                                    <div className="form-group margin-top-10" >
                                        <label htmlFor="listName">Reference Name</label>
                                        <input type="text"
                                            className="form-control" name="referenceName" id="referenceName"
                                            placeholder="Reference Name" required
                                            value={this.state.SelectedRef?.referenceName} onChange={this.onEnterReferenceName} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group margin-top-10" >
                                        <label htmlFor="NCBI_Embedded">Reference ID</label>
                                        <input type="text"
                                            className="form-control" name="NCBI_Embedded" id="NCBI_Embedded"
                                            placeholder="NCBI Embedded Content" required
                                            value={this.state.SelectedRef?.NCBI_Embedded} onChange={this.onEnterNCBIEmbedded} />
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-primary"
                                    disabled={!this.state.SelectedRef?.referenceName || !this.state.SelectedRef?.NCBI_Embedded}
                                    onClick={this.saveReference} >Save</button>
                                <button className="btn btn-outline-secondary" onClick={this.onCloseReference}> Close </button>
                            </Modal.Footer>
                        </BlockUi>
                    </Modal>

                    <Modal show={this.state.showTrackModal}
                        size="lg"
                        animation={false}
                        backdrop="static"
                        keyboard={false}>
                        <BlockUi tag="div" blocking={this.state.modaleblocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />}>
                            <Modal.Header>
                                <Modal.Title>
                                    <div>
                                        <h2 className="lead intro">Edit a Custom Track Data </h2>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {this.state.hasmodalerror && 
                                        <div className="row redcolor">
                                            <p>{this.state.modalerrors}</p>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="form-group margin-top-10" >
                                            <label htmlFor="trackname">Track Name</label>
                                            <input type="text"
                                                className="form-control" name="trackname" id="trackname"
                                                placeholder="Track Name" required
                                                value={this.state.SelectedTrack?.trackname} onChange={this.onEnterTrackname} />
                                        </div>
                                    </div>

                                    <div className="row">
                                            <div className="form-group margin-top-10" >
                                                <label htmlFor="listName">Description</label>
                                                <textarea name="description" 
                                                          id="description"  
                                                          className="form-control"
                                                          value={this.state.SelectedTrack?.description} 
                                                          onChange={this.onEntertrackDescription} />
                                            </div>
                                        </div>
                                    

                                        <div className="row margin-top-10" >
                                            {(this.state.SelectedTrack?.trackId === "0" || (this.state.SelectedTrack?.trackId !== "0" && this.state.SelectedTrack?.tracktype === "local")) &&
                                                <div className="col-md-6">
                                                    <input id="samplelist" type="radio" name="tracktype" value="local" checked={this.state.SelectedTrack?.tracktype === "local"} onChange={this.onTracktypeChange} />
                                                    <span> <label htmlFor="uploadfile" >Local upload</label></span>
                                                </div>
                                            }
                                            {(this.state.SelectedTrack?.trackId === "0" || (this.state.SelectedTrack?.trackId !== "0" && this.state.SelectedTrack?.tracktype === "remote")) &&
                                                <div className="col-md-6" >
                                                    <div>
                                                        <input id="cb_cohort" type="radio" name="tracktype" value="remote" checked={this.state.SelectedTrack?.tracktype === "remote"} onChange={this.onTracktypeChange} />
                                                        <span className="margin-left-9" ><label htmlFor="cb_cohort" >Public URL &nbsp;&nbsp;</label></span>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="row margin-top-10">
                                            {this.state.SelectedTrack?.trackId === "0" &&
                                                <div className="form-group col-md-6" >
                                                    <input id="file" type="file" accept=".bed" onChange={this.ontrackFileUpload} disabled={this.state.SelectedTrack?.tracktype === "remote"} />
                                                    <ProgressBar className="margin-top-10" now={this.state.progress1} label={`${this.state.progress1}%`} />
                                                </div>
                                            }
                                            {this.state.SelectedTrack?.trackId !== "0" && this.state.SelectedTrack?.tracktype === "local" &&
                                                <div className="form-group" >
                                                    <input id="file" type="file" accept=".bed" onChange={this.ontrackFileUpload} />
                                                    <ProgressBar className="margin-top-10" now={this.state.progress1} label={`${this.state.progress1}%`} />
                                                </div>
                                            }
                                            {this.state.SelectedTrack?.trackId === "0" &&
                                                <div className="form-group col-md-6 aligncenter" >
                                                    <textarea name="publicurl"
                                                        id="publicurl"
                                                        className="form-control"
                                                        disabled={this.state.SelectedTrack?.tracktype === "local"} 
                                                        value={this.state.trackpublicurl}
                                                        placeholder="Enter public url"
                                                        onChange={this.onEnterPublicUrl} />
                                                </div>
                                            }
                                            {this.state.SelectedTrack?.trackId !== "0" && this.state.SelectedTrack?.tracktype === "remote" &&
                                                <div className="form-group aligncenter" >
                                                    <textarea name="publicurl"
                                                        id="publicurl"
                                                        className="form-control"
                                                        value={this.state.trackpublicurl}
                                                        placeholder="Enter public url"
                                                        onChange={this.onEnterPublicUrl} />
                                                </div>
                                            }
                                        
                                        </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-primary"
                                    disabled={this.state.SelectedRef?.referenceId === "0" || !this.state.SelectedTrack?.trackname || !this.state.SelectedTrack?.description ||
                                             (this.state.SelectedTrack?.tracktype === "local" && !this.state.trackuploadFile) ||
                                             (this.state.SelectedTrack?.tracktype === "remote" && !this.state.trackpublicurl)}
                                    onClick={this.onSaveTrackData} >Save</button>
                                <button className="btn btn-outline-secondary" onClick={this.onCloseTrack}> Close </button>
                            </Modal.Footer>
                        </BlockUi>
                    </Modal>

                </div>
            </div>
        )
    };
};
