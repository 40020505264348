import { useState, createContext, useContext }  from "react";
import IUserSession from '../domain/IUserSession'

//Has bug ....
// let usersession: IUserSession = { 
//   email: "", 
//   isverified: false, 
//   isadminuser: false, 
//   SessionID: "",
//   DEPOT_webService: "", 
//   depot_token: "", 
//   gap_token: "",
//   max_search_range: 0 
// };
// let url = API_URL +'/login/getnewsession';

// const getNewSession = async () => {
//   //console.log(url);
//   const response = await fetch(url);
//   const json = await response.json();
//   usersession = json;
//   //localStorage.setItem("token", json.gap_token);
//   console.log("user session:" + usersession);
// }

export default class UserSession{
  static usersession: IUserSession = { 
    email: "", 
    isverified: false, 
    isadminuser: false, 
    verifiedmessage: "",
    SessionID: "",
    DEPOT_webService: "", 
    depot_token: "", 
    gap_token: "",
    max_search_range: 0,
    Depot_cohorts : [],
   // predictionSession: [],
  };

  // static getNewSession = async () => {
  //   let url = API_URL +'login/getnewsession';
  //   console.log(url);
  //   const response = await axios(url);
  //   const json = await response.data;
  //   this.usersession = json;
  //   sessionStorage.setItem("userSession", json);
  //   //UserSession.setUserSession(this.usersession);
  //   localStorage.setItem("token", json.gap_token);
  //   console.log("user token:" + json.gap_token);
  // }

  // static fetchjUserSession () {
  //   let url = API_URL +'admin/getAppReferences';
  //   axios.get(url)
  //     .then(response => {
  //       const json = response.data;
  //       //const response = await axios.get(url);
  //      // const json = await response.data;
  //       this.usersession = json;
  //       sessionStorage.setItem("userSession", json);
  //     })
  //     .catch(error => {
  //       console.error('There was an error!' + error.message);
  //     });
  // }

  static getUserSession(){
    // if(localStorage.token === null || localStorage.token === ""){
    //   this.getNewSession();
    //   //return this.usersession;
    // }
    // else{
    //   let session = sessionStorage.getItem("userSession");
    //   if( session === null){
    //     this.fetchjUserSession();
        
    //   }
    //   else {
    //     this.usersession = JSON.parse(session);
    //   }
    // }

    return this.usersession;
  }

  // static setUserSession(ausersession : IUserSession){
  //   this.usersession = ausersession;
  //   const userContext = createContext<IUserSession>(ausersession);
  // }

  // static token(){
  //   return this.usersession.gap_token;
  // }
}
