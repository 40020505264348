import * as React from "react";
import { UISref, Transition, UISrefActive } from "@uirouter/react";
import {removeOrcidCode} from '../utils/gapCommon';

export default class Variants extends React.Component<Transition> {
  render() {
    removeOrcidCode("variants", this.props);
    return (
    
    <div className="gap-page-content">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className='breadcrumb-item'>
              <UISref to="home"><a>Home</a></UISref>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Variants</li>
          </ol>
        </nav>

        <h1>Variants</h1>
        <p>
          Use genomic data to perform genome wide association studies, search for variants of interest, and compute the distance between samples.
        </p>
        <hr />
        <br />
        <div>
          <UISrefActive class="active">
            <UISref to="GWAS"><a>GWAS: </a></UISref> (Genome-Wide Association Study) using tuberculosis genomes.
          </UISrefActive>
        </div>
        <div>
          <UISrefActive class="active">
            <UISref to="Clustering"><a>Clustering: </a></UISref> Cluster samples using genomic information.
          </UISrefActive>
        </div>
        <div>
          <UISrefActive class="active">
            <UISref to="VariantSearch"><a>Variant Search: </a></UISref>Search for variants and create cohorts using genomic information.
          </UISrefActive>
        </div>
        <div>
          <UISrefActive class="active">
            <UISref to="Distance"><a>Distance: </a></UISref>compute the genomic distance between samples. Search for samples that are close neighbors using genomic distance.
          </UISrefActive>
        </div>
        <div>
          <UISrefActive class="active">
            <UISref to="Visualization"><a>Cohort Visualization: </a></UISref>visualize genomic information for cohorts of patients.
          </UISrefActive>
        </div>
      </div>
    </div>
   );
  }
}


