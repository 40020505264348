import React from 'react';
import ReactDOM from 'react-dom';
import { UIRouter, pushStateLocationPlugin } from "@uirouter/react";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/gap.css';
import App from './App'
import * as AppConstants from './App.constants'
import {setupInterceptorsTo} from "./Interceptors";
import axios from "axios";

setupInterceptorsTo(axios);

ReactDOM.render(
  <UIRouter 
      plugins={[pushStateLocationPlugin]} 
      states={[ //AppConstants.orcidcallbackState, 
                AppConstants.aboutState,
                AppConstants.faqState,
                AppConstants.userguideState,
                AppConstants.releasenotesState,
                AppConstants.homeState,
                AppConstants.variantsState,
                AppConstants.genomeBrowserState,
                AppConstants.resistanceSate,
                AppConstants.TBresistancePrediction,
                AppConstants.NTMresistancePrediction,
                AppConstants.tbportalsdata,
                AppConstants.GWASState,
                AppConstants.ClusteringState,
                AppConstants.VariantSearchState,
                AppConstants.DistanceState,
                AppConstants.CohortVisualizationState,
                AppConstants.UserVCFFilesState,
                AppConstants.AdminVCFFilesState,
                AppConstants.AdminUserState,
                AppConstants.ListManagementState,
                AppConstants.LoadTbprofileResultState,
                AppConstants.MgmReferenceState,
                AppConstants.UserMetadataState
              ]}>
      <App/>
  </UIRouter>,
  document.getElementById("root")
);
