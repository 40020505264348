import React from 'react';
import parse from 'html-react-parser';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter'
import * as BSIcon from "react-icons/bs"
import {calculateTablePage} from '../../utils/gapCommon';

export default class SampleListTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = ({selectedrows: []});
  }


  ListColumns = [
    { dataField: 'listname', text: 'List Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'200px' },
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'listtype', text: 'List Type', sort: true,  headerAlign: 'center',
        headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap' , headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'username', text: 'Owner', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    { 
      dataField: 'listsource', text: 'List Source', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },  
    { 
      dataField: 'totallistnumber', text: 'List Number', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'createdate', text: 'Create Date', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'vcf', text: 'VCF', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle',width:'400px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable,
      formatter: (cell, row) => {
        if(row.vcf !== undefined){
          var vcfs = row.vcf;
          vcfs = vcfs.replaceAll(" ", "<br>");
          return <div>{ parse(vcfs) }</div>
        }
        else return <div>{row.vcf} </div>
      }
    }, 
    { 
      dataField: 'groupnames', text: 'User Group', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    { 
      dataField: 'depot_cohorts', text: 'Cohorts', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable
    }, 
    {
      dataField: '', text: '', sort: false, headerAlign: 'center',
      headerStyle: { verticalAlign: 'middle', width: '130px' },
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable,
      formatter: (cell, row) => {
        return <div>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onDownloadList(row)}>
            <BSIcon.BsDownload className="margin-bottom-3" title="Download List" />
          </button>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditList(row)}>
            <BSIcon.BsPencil className="margin-bottom-3" title="Edit List" />
          </button>
          <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onDeleteList(row)}>
            <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete List" />
          </button>

        </div>;
      }
    }
  ];

  onDeleteList =(row)=>{
    var r = window.confirm("Do you want to delete list " + row.listname);
		if (r === false) {
		    return;
		}

    this.props.onDeleteList(row);
  }

  onEditList=(row)=>{
    this.setState({selectedrows: [row.listid]});
    this.props.onEditList(row);
	}

  onDownloadList=(row)=>{
    this.setState({selectedrows: [row.listid]});
    this.props.onDownloadList(row);
	}

  handleOnSelect = (row, isSelect) => {
    var selectedrows = this.state.selectedrows;
    if (isSelect) {
      if(!selectedrows.includes(row)){
        selectedrows.push(row.listid);
      } 
    }
    else{
      selectedrows = selectedrows.filter(function(r) { 
        return r !== row.listid;
      });
    }

    this.setState({selectedrows: selectedrows});
    this.props.OnRowSelect(selectedrows);
  }
  
  render(){
    const selectedRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectColumn: false,
      hideSelectAll: true,
      style: { backgroundColor: "#c8e6c9" },
      selected: this.state.selectedrows,
      nonSelectable: this.props.nonSelected,
      onSelect: this.handleOnSelect,
    };

    const sizePerPageOptionRenderer = ({
      text,
      page,
      onSizePerPageChange
    }) => (
      <li
        key={ text }
        role="presentation"
        className="dropdown-item"
      >
        <a
          href="#"
          tabIndex="-1"
          role="menuitem"
          data-page={ page }
          onMouseDown={ (e) => {
            e.preventDefault();
            onSizePerPageChange(page);
          } }
        >
          { text }
        </a>
      </li>
    );

    const options = {
      sizePerPageOptionRenderer,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
     
      <div>
        <BootstrapTable
          bootstrap4
          keyField="listid"
          ref={n => this.node = n}
          data={this.props.searchResults}
          columns={this.ListColumns}
          selectRow={selectedRow}
          pagination={paginationFactory(options)}
        />
      </div>
    );
  }
};