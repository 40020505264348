import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter';

export default class TBPortalTable extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            showtbprofiler: false,
            TbProfileResults: "",
            disabledcompare: true,
            Jasonchecked: false,
            Textchecked: false,
            Jasonchecked_List: false,
            Textchecked_List: false,
        };
    }

    TBPortalResultColumns = [{ 
      dataField: 'SampleId', text: 'Sample', sort: true,  headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', width:'110px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      formatter: (cell, row) => {
            return <div  className="hyperlinkCSS" onClick={() => {this.getsampleresult(row.SampleId);}}>{row.SampleId}</div>;
      }
    }, 
    
    { 
        dataField: 'main_lin', text: 'main_lineage', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'100px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'drtype', text: 'DR_type', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'num_dr_variants', text: 'num_dr_variants', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'num_other_variants', text: 'num_other_variants', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'rifampicin', text: 'rifampicin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'isoniazid', text: 'isoniazid', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'pyrazinamide', text: 'pyrazinamide', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'ethambutol', text: 'ethambutol', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'streptomycin', text: 'streptomycin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'fluoroquinolones', text: 'fluoroquinolones', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'moxifloxacin', text: 'moxifloxacin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'ofloxacin', text: 'ofloxacin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'levofloxacin', text: 'levofloxacin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },

    { 
        dataField: 'ciprofloxacin', text: 'ciprofloxacin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },

    { 
        dataField: 'aminoglycosides', text: 'aminoglycosides', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'amikacin', text: 'amikacin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'kanamycin', text: 'kanamycin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'capreomycin', text: 'capreomycin', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'ethionamide', text: 'ethionamide', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'para-aminosalicylic_acid', text: 'para-aminosalicylic_acid', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'linezolid', text: 'linezolid', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'bedaquiline', text: 'bedaquiline', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'clofazimine', text: 'clofazimine', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },
    { 
        dataField: 'delamanid', text: 'delamanid', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }];

    getsampleresult=(sampleid)=>{
        this.props.getsampleresult(sampleid);
    }

    DownloadSampleListResult =() =>{
      var downloadfilename = "";
      var file;
      if (this.props.sampletype === "l") {
        var listjson = JSON.parse(this.props.selectedSamplelist);
        downloadfilename = listjson.listname;
      } else if (this.props.sampletype === "c") {
        var cohortjson = JSON.parse(this.props.selectedCohort);
        downloadfilename = cohortjson.name;
      } else {
        return;
      }

      if (this.state.Textchecked_List) {
        file = new Blob([this.props.samplelistresult_Text], {
          type: "application/txt",
        });
        this.downloaddata(file, downloadfilename + "_Text");
      }

      if (this.state.Jasonchecked_List) {
        file = new Blob([this.props.samplelistresult_Json], {
          type: "application/txt",
        });

        if(this.state.Textchecked_List){
          setTimeout(() => {this.downloaddata(file, downloadfilename + "_Json");}, 500);
        }
        else{
          this.downloaddata(file, downloadfilename + "_Json");
        }
      }
    }

    DownloadSampleResult = () => {
        var downloadfilename = this.props.selectedSampleid;
        var file;
        // if (this.props.sampletype === 's' && this.props.selectedSampleid !== undefined) {
        //     downloadfilename = this.props.selectedSampleid.label;
        // }
        

        if (this.state.Textchecked) {
          file = new Blob([this.props.sampleresult_Text], {
            type: "application/txt",
          });
          this.downloaddata(file, downloadfilename + "_Text");
        }

        if (this.state.Jasonchecked) {
          file = new Blob([this.props.sampleresult_Json], {
            type: "application/txt",
          });

          if(this.state.Textchecked){
            setTimeout(() => {this.downloaddata(file, downloadfilename + "_Json");}, 500);
          }
          else{
            this.downloaddata(file, downloadfilename + "_Json");
          }
        }
    }

    downloaddata =(file, downloadfilename) =>{
        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a         = document.createElement('a');
        a.href        = fileURL; 
        a.target      = '_blank';
        a.download    = downloadfilename;
        document.body.appendChild(a);
        a.click();
    }

    onTextchecked =(event) => {
      var textchecked = !this.state.Textchecked;
      this.setState({Textchecked: textchecked});
	  }

    onJasonchecked =(event) => {
      var jasoncheck = !this.state.Jasonchecked;
      this.setState({Jasonchecked: jasoncheck});
	  }

    onListTextchecked =(event) => {
      var textchecked = !this.state.Textchecked_List;
      this.setState({Textchecked_List: textchecked});
	  }

    onListJasonchecked =(event) => {
      var jasoncheck = !this.state.Jasonchecked_List;
      this.setState({Jasonchecked_List: jasoncheck});
	  }

    render() {
      const selectedRow = {
        mode: "radio",
        clickToSelect: true,
        hideSelectColumn: true,
        style: { backgroundColor: "#c8e6c9" }
      };

        // const TBPortalPagination = {
        //   custom: true,
        //   totalSize: this.props.searchResults.length,
        //   sizePerPage: 10,
        //   page: 1,
        //   sizePerPageList: [
        //       { text: "10", value: 10 },
        //       { text: "25", value: 25 },
        //       { text: "50", value: 50 },
        //       { text: "100", value: 100 },
        //       ],
        // };

        const sizePerPageOptionRenderer = ({
          text,
          page,
          onSizePerPageChange
        }) => (
          <li
            key={ text }
            role="presentation"
            className="dropdown-item"
          >
            <a
              href="#"
              tabIndex="-1"
              role="menuitem"
              data-page={ page }
              onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
              } }
            >
              { text }
            </a>
          </li>
        );

        const options = {
          sizePerPageOptionRenderer,
          sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
        };

        return (
          <div>
            {this.props.samplelistresultfound && (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      id="cb_text"
                      type="checkbox"
                      name="cb_text"
                      checked={this.state.Textchecked_list}
                      onChange={this.onListTextchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; Text </label>
                    <input
                      id="cb_text"
                      className="margin-left-10"
                      type="checkbox"
                      name="cb_text"
                      checked={this.state.Jasonchecked_List}
                      onChange={this.onListJasonchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; JSON</label>
                    <button
                      id="singledownloadtable"
                      type="button"
                      className="btn btn-success margin-left-10"
                      disabled={
                        !this.state.Textchecked_List && !this.state.Jasonchecked_List
                      }
                      onClick={this.DownloadSampleListResult}
                    >
                      <span className="glyphicon glyphicon-save"></span>Download
                    </button>
                  </div>
                </div>
                <div>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  ref={n => this.node = n}
                  data={this.props.searchResults}
                  columns={this.TBPortalResultColumns}
                  selectRow={selectedRow}
                  pagination={paginationFactory(options)}
                />
              </div>
                {/* <div className='margin-top-10'>
                  <PaginationProvider
                    pagination={paginationFactory(TBPortalPagination)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <div >
                        <div>
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={this.props.searchResults}
                          columns={this.TBPortalResultColumns}
                          selectRow={selectedRow}
                          {...paginationTableProps}
                        />
                        </div>
                        <div className="row react-bootstrap-table-pagination">
                          <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </div>
                      </div>
                    )}
                  </PaginationProvider>
                </div> */}
                <hr></hr>
              </div>
            )}
            {this.props.sampleresultfound && (
              <div className=" margin-top-10">
                <div className="row">
                 
                  <div className="col-md-6">
                    <input
                      id="cb_text"
                      type="checkbox"
                      name="cb_text"
                      checked={this.state.Textchecked}
                      onChange={this.onTextchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; Text </label>
                    <input
                      id="cb_text"
                      className="margin-left-10"
                      type="checkbox"
                      name="cb_text"
                      checked={this.state.Jasonchecked}
                      onChange={this.onJasonchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; JSON</label>
                    <button
                      id="singledownloadtable"
                      type="button"
                      className="btn btn-success margin-left-10"
                      disabled={
                        !this.state.Textchecked && !this.state.Jasonchecked
                      }
                      onClick={this.DownloadSampleResult}
                    >
                      <span className="glyphicon glyphicon-save"></span>Download
                    </button>
                  </div>
                </div>

                <div className="row margin-top-10">
                  <br />
                  <pre className="preOut">
                    <code>{this.props.sampleresult_Text}</code>
                  </pre>
                </div>
              </div>
            )}
          </div>
        );
    }
};