import React from "react";
import './Footer.css'

const Footer = () => {
    return (
        <div className="px-md-5 text-white Footer-backgroud">
            <div className="container py-5 ">
                <div className="row mb-1 mb-xl-5">
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <p className="mb-2 fw-bold">Connect</p>
                        <ul className="list-unstyled footer-links">
                            <li><a className="small text-reset text-decoration-none" href="https://twitter.com/niaidbioit" target="_blank">
                                <i className="fa fa-twitter  fa-lg"></i> Twitter</a>
                            </li>
                            <li><a className="small text-reset text-decoration-none" href="https://www.youtube.com/user/NIAIDBioinformatics" target="_blank">
                                <i className="fa fa-youtube-play fa-lg"></i> YouTube</a>
                            </li>
                            <li><a className="small text-reset text-decoration-none" href="mailto: TBPortalSupport@nih.gov" target="_top">
                                <i className="fa fa-envelope fa-lg"></i> Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <p className="mb-1 fw-bold">Links</p>
                        <ul className="list-unstyled">
                            <li><a className="small text-reset text-decoration-none" href="https://www.niaid.nih.gov" target="_blank">NIAID Website</a></li>
                            <li><a className="small text-reset text-decoration-none" href="https://www.nih.gov/web-policies-notices" target="_blank">Privacy Policy</a></li>
                            <li><a className="small text-reset text-decoration-none" href="https://tbportals.niaid.nih.gov/disclaimer" target="_blank">Disclaimer</a></li>
                            <li><a className="small text-reset text-decoration-none" href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" target="_blank">Vulnerability Disclosure Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <p className="mb-1 fw-bold">Related Government Websites</p>
                        <ul className="list-unstyled">
                            <li className="">
                                <a className="small text-reset text-decoration-none" href="http://www.nih.gov/" target="_blank">National Institutes of Health</a>
                            </li>
                            <li className="">
                                <a className="small text-reset text-decoration-none" href="http://www.hhs.gov/" target="_blank">Health &amp; Human Services</a>
                            </li>
                            <li className="">
                                <a className="small text-reset text-decoration-none" href="http://www.usa.gov" target="_blank">USA.gov</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="border-5" />
                <div className="row">
                    <div className="col-12">
                        <p className="small mb-0 text-reset"><span className="fw-bold">National Institutes of Health</span> — Turning
                            Discovery Into Health®</p>
                        <p className="small text-muted">National Institutes of Health (NIH), 9000 Rockville Pike, Bethesda, Maryland 20892
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;