/* eslint-disable no-restricted-globals */
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter'
import $ from 'jquery'

var selectedpage = 1;
var selectedrowid = [];
var select_id = "";
var select_id2 = "";

export default class DistanceDiffTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  popup_multipHyperlink = function(g_id, id2, grouptype){
		select_id2="";
		select_id="";
		
		$(".custom-menu").finish().toggle(100).css({
		        top: window.event.pageY + "px",
		        left: window.event.pageX + "px"
		  }); // In the right position (the mouse)
		
		$('.custom-menu li').hide();
		$('.custom-menu li').filter('[data-label=' + grouptype + ']').show();
	
		if(id2 != null){
			select_id2 = id2.split("-")[0];
		}
		var first_id = g_id.split("-");
	  select_id = first_id[0];
	}

  gotoextern = function(target){
		switch (target) {
			case "ncbi_gene":
				window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
				break;
			case "geo":
				window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
				break;
			case "kegg":
				window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
				break;
			case "ncbi_protein":
				window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
				break;
			case "uniprot":
				window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
				break;
			case "tbrnat":
				window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
				break;
        default:
          break;
		}
	}

  opendetail = (position, type) => {
		this.props.opendetail(position, type);
	};

  ResultColumns = [
    { 
      dataField: 'POS', text: 'POS', sort: true,  headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { dataField: 'REF', text: 'REF', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'150px' }, 
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'ALT', text: 'ALT', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    },  
    { 
        dataField: 'GT1', text: 'GT1', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'GT2', text: 'GT2', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'Impact', text: 'Impact', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'120px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'AAChange', text: 'AA Change', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'GeneID', text: 'Gene ID', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><a class="link-primary" href={() => false} onClick={() => {this.popup_multipHyperlink(row.GeneID,row.RefseqID, 'Gene'); }}>{row.GeneID}</a></div>;
        }
    },
    { 
      dataField: 'ProteinID', text: 'Protein ID', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      formatter: (cell, row) => {
        return <div><a class="link-primary" href={() => false} onClick={() => this.popup_multipHyperlink(row.ProteinID, null, 'Protein')} >{row.ProteinID}</a></div>;
      }
    }, 
    { 
      dataField: 'ProteinName', text: 'Protein Name', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
  ];

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  handleClickOutside = evt => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  render(){
    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      selected: selectedrowid,
    };

    const defaultSorted = [{
      dataField: 'P',
      order: 'asc'  
    }];

    const VariantResultPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.Results.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
      <div>
        <div>
        
            <PaginationProvider pagination={paginationFactory(VariantResultPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    ref={ n => this.node = n }
                    data={this.props.Results}
                    columns={this.ResultColumns}
                    selectRow={ selectedRow }
                    defaultSorted={ defaultSorted } 
                    {...paginationTableProps}
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
      
        </div>
        <div>
          <ul className='custom-menu'>
              <li data-label="Gene"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('ncbi_gene')} >NCBI Gene Database</a></li>
              <li data-label="Gene"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('geo')} >NCBI Gene Expression Omnibus</a></li>
              <li data-label="Gene"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('kegg')} >KEGG Genes Database</a></li>
              <li data-label="Gene"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('tbrnat')} >TBRNAT</a></li>
              <li data-label="Protein"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('ncbi_protein')} >NCBI Protein</a></li>
              <li data-label="Protein"><a class="link-primary" href={() => false} onClick={() => this.gotoextern('uniprot')} >UniProt</a></li>
          </ul>
        </div>
    </div>
    );
  }
};