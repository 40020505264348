import React from 'react'

function tableHeaderSortFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        { filterElement }
        { column.text }
        { sortElement }
      </div>
    );
  }

export default tableHeaderSortFormatter;