import * as React from "react";
import { UISref, Transition} from "@uirouter/react";
import VCFFile from "./VCFFiles"

export default class AdminVCFFiles extends React.Component <Transition> {
    
    constructor(props: Transition) {
        super(props);
	}

    render() {
        var usersession = JSON.parse(sessionStorage.userSession);
        if(!usersession.isadminuser){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        return (
            <VCFFile IsAdmin={true} ></VCFFile>
        );
    }
  }