import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import './ResistancePrediction.css'

export default class RPTable extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.state = { 
      showtbprofiler : false, 
      TbProfileResults:"",
      disabledcompare:true,
      selectedrows: []
    };
  }

  showfailed =(rowdata) =>{
		if(rowdata.status === "failed"){
				return true;
		}

		return false;
  }

  isProcessing=(rowdata)=>{
		if(rowdata.status === "completed"){
			return false;
		}
		else
			return true;
  }

  isCanceling=(rowdata)=>{
		if(rowdata.status === "cancelled"){
			return true;
		}
		else
			return false;
  }

  ShowResult=(rowdata)=>{
    var version = rowdata.predictionVersion;
    var textblock = version + "\n" + rowdata.Text;
    this.setState ({showtbprofiler : true, TbProfileResults: textblock});
  }

  deleteResult=(rowdata)=>{
    this.props.deleteResult(rowdata.predictionid);
  }

  cancelProcessing=(rowdata)=>{
    this.props.cancelProcessing(rowdata.predictionid);
  }

  handleOnSelect = (row, isSelect) => {
    var selectedrows = this.state.selectedrows;
    row.compared = isSelect;

    if (isSelect) {
      if(!selectedrows.includes(row.predictionid)){
        selectedrows.push(row.predictionid);
      } 
    }
    else{
      selectedrows = selectedrows.filter(function(r) { 
        return r !== row.predictionid;
      });
    }

    var compareddisabled = true;
    if(selectedrows.length > 1) compareddisabled = false;

    this.setState({selectedrows: selectedrows, disabledcompare: compareddisabled});
  }

  comparereport=()=>{
    this.props.comparereport(this.props.searchResults);
  }

  downloadResult =() =>{
    var downloadfilename = "Resistance Prediction";
    var file;
  
      file = new Blob([this.state.TbProfileResults], {
        type: "application/txt",
      });
      var fileURL = URL.createObjectURL(file);
      var a         = document.createElement('a');
      a.href        = fileURL; 
      a.target      = '_blank';
      a.download    = downloadfilename;
      document.body.appendChild(a);
      a.click();
  }

  RPresultColumns = [
    { 
      dataField: 'fasq1', text: 'Fasq1', sort: true,  headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    }, 
    { 
      dataField: 'fasq2', text: 'Fasq2', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'120px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    },
    { 
        dataField: 'predictionType', text: 'Prediction Type', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap'
    }, 
    { 
      dataField: '', text: 'Result', sort: false, headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', width:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', 
      formatter: (cell, row) => {
        if(this.showfailed(row)){
          return <div>
                  <span style={{fontsize:"10px"}}>No Result&nbsp;&nbsp;</span>
                    <button type="button" className="btn btn-primary"
                      onClick={() => this.deleteResult(row)}>
                      <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Delete
                    </button>
                </div>
        }
        else{
          return <div>
                  {this.isCanceling(row) && 
                    <button type="button" className="btn btn-primary" disabled={true}>
                      <span><i className="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;</span>Canceling
                    </button>
                  }
                  {!this.isCanceling(row) &&
                    <button type="button" className="btn btn-primary"
                      disabled={this.isProcessing(row)}
                      onClick={() => this.ShowResult(row)}>
                      {this.isProcessing(row) === false &&
                        <div>
                          <span><i className="fa fa-save"></i>&nbsp;&nbsp;</span>Result
                        </div>
                      }
                      {this.isProcessing(row) &&
                        <div>
                          <span><i className="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;</span> Processing
                        </div>
                      }
                    </button>
                  }&nbsp;&nbsp;
                  {this.isProcessing(row) && !this.isCanceling(row) &&
                    <button type="button" className="btn btn-primary"
                      disabled={this.isCanceling(row)}
                      onClick={() => this.cancelProcessing(row)}>
                      <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Cancel
                    </button>
                  }
                  {!this.isProcessing(row) && !this.isCanceling(row) &&
                    <button type="button" className="btn btn-primary"
                      onClick={() => this.deleteResult(row)}>
                      <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Delete
                    </button>
                  }
                  </div>;
        }
      }
    }, 
  ];

  render(){
    let notSelectableRows = [];

    const getNonSelectableRows = () => {
      let nonSelectable = [];

      this.props.searchResults.forEach((row, index) => {
        if (this.isProcessing(row) || this.showfailed(row) ) {
          nonSelectable.push(row.predictionid);
        }
      });

      return nonSelectable;
    };

    notSelectableRows = getNonSelectableRows();

    const selectedRow = {
      mode: "checkbox",
      clickToSelect: false,
      onSelect: this.handleOnSelect,
      nonSelectable: notSelectableRows,
      hideSelectAll:true
    };

    const RPPagination = {
      custom: true,
      totalSize: this.props.searchResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
      <div>

        <div>
					<label className="displaylable">Submitted Jobs</label>
					<button  className="btn btn-primary comparebutton" disabled={this.state.disabledcompare}
						onClick={this.comparereport}>
						Compare
					</button>
				</div>
       
        <div>
            <PaginationProvider pagination={paginationFactory(RPPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField="predictionid"
                    ref={ n => this.node = n }
                    data={this.props.searchResults}
                    columns={this.RPresultColumns}
                    selectRow={ selectedRow }
                    {...paginationTableProps}
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
        </div>
        {this.state.showtbprofiler &&
          <div className="row margin-top-15" >
            <div>
              <button
                className="btn btn-primary comparebutton "
                onClick={this.downloadResult}>
                <span className="fa fa-save"></span>&nbsp;&nbsp;Download
              </button>
            </div>
            <pre className="preOut">
              <code>{this.state.TbProfileResults}</code>
            </pre>
          </div>
        }
    </div>
    );
  }
};