import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

var selectedpage = 1;

export default class GAPSimpleTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render(){
    var idname = "id";
    if(this.props.id !== undefined) idname = this.props.id;
    
    const VariantResultPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.tableResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
      <div>
        <div>
            <PaginationProvider pagination={paginationFactory(VariantResultPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField={idname}
                    data={this.props.tableResults}
                    columns={this.props.columns}
                    defaultSorted={ this.props.defaultSorted } 
                    {...paginationTableProps}
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
        </div>
    </div>
    );
  }
};