import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import headerFormatter from '../../utils/tableHeaderSortFormatter';
import {calculateTablePage} from '../../utils/gapCommon';
import $ from 'jquery';
import './VariantSearch.css';

var selectedpage = 1;
var selectedrowid = [];
var select_id = "";
var select_id2 = "";

class VariantTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  popup_multipHyperlink = function (g_id, id2, grouptype) {
    select_id2 = "";
    select_id = "";

    $(".custom-menu").finish().toggle(100).css({
        top: window.event.pageY + "px",
        left: window.event.pageX + "px",
      }); // In the right position (the mouse)

    $(".custom-menu").show();
    $(".custom-menu li")
      .filter("[data-label=" + grouptype + "]")
      .show();

    if (id2 != null) {
      select_id2 = id2.split("-")[0];
    }
    var first_id = g_id.split("-");
    select_id = first_id[0];
  };

  gotoextern = function(target){
		switch (target) {
			case "ncbi_gene":
				window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
				break;
			case "geo":
				window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
				break;
			case "kegg":
				window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
				break;
			case "ncbi_protein":
				window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
				break;
			case "uniprot":
				window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
				break;
			case "tbrnat":
				window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
				break;
      default:
        break;
		}
	}

  opendetail = (currentrow, type) => {
		this.props.opendetail(currentrow, type);
	};	

  createVSList =(row) => {
    this.props.createVSList(row, );
	};

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = evt => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  VariantResultColumns = [
    { 
      dataField: 'Position', text: 'Position', sort: true,  headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      formatter: (cell, row) => {
        return <div><button className="buttonLink" onClick={() => {this.opendetail(row, 'all')}}>{row.Position}</button></div>;
      }
    }, 
    { dataField: 'RefAllele_display', text: 'Ref Allele', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'150px' }, 
        headerClasses: "tablecellwrap",  classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.RefAllele}
    }, 
    { 
        dataField: 'RefCount', text: 'Ref Count', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><button className="buttonLink" onClick={() => {this.opendetail(row, '0/0');}}>{row.RefCount}</button></div>;
        }
    },  
    { 
        dataField: 'AltAllele_display', text: 'Alt Allele', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.AltAllele}
    }, 
    { 
        dataField: 'AltCount', text: 'Alt Count', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><button className="buttonLink" onClick={() => {this.opendetail(row, '1/1');}}>{row.AltCount}</button></div>;
        }
    }, 
    { 
        dataField: 'MissingCount', text: 'Missing Count', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        formatter: (cell, row) => {
          return <div><button className="buttonLink" onClick={() => {this.opendetail(row, '.');}}>{row.MissingCount}</button></div>;
        }
    }, 
    { 
        dataField: 'Impact', text: 'Impact', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'120px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
        dataField: 'NTChange_display', text: 'NT Change', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.NTChange}
    }, 
    { 
        dataField: 'AAChange_display', text: 'AA Change', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.AAChange}
    }, 
    { 
        dataField: 'GeneName_display', text: 'Gene Name', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.GeneName}
    }, 
    { 
        dataField: 'GeneID_display', text: 'Gene ID', sort: true, headerAlign: 'center', 
        headerStyle: { verticalAlign:'middle', width:'80px' }, 
        headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
        title: (cell, row, rowIndex, colIndex) =>  {return row.GeneID},
        formatter: (cell, row) => {
          return <button className="buttonLink" onClick={() => {this.popup_multipHyperlink(row.GeneID,row.RefseqID, 'Gene'); }}><span className="cellwrapetext">{row.GeneID_display}</span></button>;
        }
    },
    { 
      dataField: 'ProteinID', text: 'Protein ID', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'80px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      formatter: (cell, row) => {
        return <button className="buttonLink" onClick={() => this.popup_multipHyperlink(row.ProteinID, null, 'Protein')} ><span className="cellwrapetext">{row.ProteinID}</span></button>;
      }
    }, 
    { 
      dataField: 'ProteinName', text: 'Protein Name', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
      dataField: 'cohort', text: 'Variant List', sort: false, headerAlign: 'center', hidden: !this.props.isverified,
      headerStyle: { verticalAlign:'middle', width:'100px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter,
      formatter: (cell, row) => {
        if(row.VSList === '' ){
          return <div><button className="buttonLink" onClick={() => this.createVSList(row)} >Create Sample List</button></div>;
        }
        return <span style={{fontsize:"10px"}}>{row.VSList}</span>;
      }
    }, 
  ];

  // calculatePage = () => {
  //   if(this.node === undefined) return;
  //   if(this.props.selectedrowid === "") return;

  //   selectedrowid = [this.props.selectedrowid];
  //   var datasource = this.node.paginationContext;
  //   var { currSizePerPage } = datasource;
  //   var sortedData = datasource.props.data;

  //   var pos = 0;
   
  //   for (var i = 0; i < sortedData.length; i++) {
  //     if (sortedData[i].id === (this.props.selectedrowid)) {
  //       selectedpage = parseInt(pos / currSizePerPage) + 1;
  //       break;
  //     } else {
  //       pos++;
  //     }
  //   }
  // };

  render(){
    //this.selectedpage = this.calculatePage();
    [selectedpage, selectedrowid] = calculateTablePage (this.node, this.props.selectedrowid, "id");
    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      selected: selectedrowid,
      style: { backgroundColor: "#c8e6c9" },
    };

    const defaultSorted = [{
      dataField: 'P',
      order: 'asc'  
    }];

    const VariantResultPagination = {
      custom: true,
      page: selectedpage,
      totalSize: this.props.searchResults.length,
      sizePerPage:10,
      sizePerPageList: [ {text: '10', value: 10}, {text: '25', value: 25}, { text: '50', value: 50}, { text: '100', value: 100} ],
    };

    return (
      <div>
        <div>
        
            <PaginationProvider pagination={paginationFactory(VariantResultPagination)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    ref={ n => this.node = n }
                    data={this.props.searchResults}
                    columns={this.VariantResultColumns}
                    selectRow={ selectedRow }
                    defaultSorted={ defaultSorted } 
                    {...paginationTableProps}
                  />
                  <div className="row react-bootstrap-table-pagination">
                    <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
      
        </div>
        <div>
          <ul className='custom-menu'>
              <li data-label="Gene"><button className="buttonLink" onClick={() => this.gotoextern('ncbi_gene')} >NCBI Gene Database</button></li>
              <li data-label="Gene"><button className="buttonLink" onClick={() => this.gotoextern('geo')} >NCBI Gene Expression Omnibus</button></li>
              <li data-label="Gene"><button className="buttonLink" onClick={() => this.gotoextern('kegg')} >KEGG Genes Database</button></li>
              <li data-label="Gene"><button className="buttonLink" onClick={() => this.gotoextern('tbrnat')} >TBRNAT</button></li>
              <li data-label="Protein"><button className="buttonLink" onClick={() => this.gotoextern('ncbi_protein')} >NCBI Protein</button></li>
              <li data-label="Protein"><button className="buttonLink" onClick={() => this.gotoextern('uniprot')} >UniProt</button></li>
          </ul>
        </div>
    </div>
    );
  }
};

export default VariantTable;
