import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next/dist/react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as BSIcon from "react-icons/bs";
import headerFormatter from '../../../utils/tableHeaderSortFormatter'

export default class ReferenceTable extends React.Component {
  selectedRows = [];

  constructor(props) {
    super(props);
    console.log(props);
  }

  //Define Columns for Group, User and List
  ReferenceColumns = [
    { dataField: 'referenceName', text: 'Reference Name', sort: true, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', maxwidth:'200px'},
      headerClasses: "tablecellwrap",  classes: 'tablecellwrap'
    }, 
    { 
      dataField: 'NCBI_Embedded', text: 'NCBI Embedded Parameter', sort: false,  headerAlign: 'center',
      headerStyle: { verticalAlign:'middle', maxwidth:'500px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', headerFormatter: headerFormatter
    }, 
    { 
      dataField: 'isdefault', text: 'Default', sort: false, headerAlign: 'center', 
      headerStyle: { verticalAlign:'middle', width:'160px' }, 
      headerClasses: "tablecellwrap", classes: 'tablecellwrap', 
      formatter: (cellContent, row) => {
        if(row.isdefault){
          return(
            <div className="checkbox disabled">
              <label>
                <input type="checkbox" checked={ row.isdefault } />
              </label>
            </div>
          )
        }
        else{
          return (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={ row.isdefault } onChange={event => this.onDefaultChange(event, row)} />
              </label>
            </div>
          )
        }
      }
    }, 
    {
      dataField: '', text: '', sort: false, headerAlign: 'center',
      headerStyle: { verticalAlign: 'middle', width: '130px' },
      headerClasses: "tablecellwrap", classes: 'tablecellwrap',
      hidden: this.props.IsSelectTable,
      formatter: (cell, row) => {
        return <div>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onEditReference(row)}>
            <BSIcon.BsPencil className="margin-bottom-3" title="Edit Reference" />
          </button>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => this.onViewReference(row)}>
            <BSIcon.BsViewStacked className="margin-bottom-3" title="View Reference" />
          </button>
          {(!row.isdefault) &&
            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onDeleteReference(row)}>
              <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete Reference" />
            </button>
          }
        </div>;
      }
    }
  ];

  onEditReference =(row) =>{
    this.props.onEditReference(row);
  }

  onDeleteReference =(row)=>{
    var r = window.confirm("Do you want to delete Reference " + row.referenceName);
		if (r === false) {
		    return;
		}

    this.props.onDeleteReference(row);
  }

  onViewReference=(row)=>{
    this.props.onViewReference(row);
	}

  onDefaultChange =  (event, row) => {
    const { checked } = event.target;
    row.isdefault = checked;
    this.props.updateReference(row);
  }

  render() {
    const selectedRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      //onSelect: this.onViewReference,
      style: { backgroundColor: "#c8e6c9" }
    };

    return (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="referenceId"
          ref={(n) => (this.node = n)}
          data={this.props.searchResults}
          selectRow={selectedRow}
          columns={this.ReferenceColumns}
        />
      </div>
    );
  }
};