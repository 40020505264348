import * as React from "react";
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import {removeOrcidCode} from './utils/gapCommon';

export default class UserGuide extends React.Component<Transition> {
  
  render() {
    removeOrcidCode("userguide", this.props);
    return (
      <div className="gap-page-content">
        <div className="container">
          <h3>User Guide</h3>
          <hr />
          <p>
            <a href="Download/G-AP_v1.17_userGuide_03MAY2024.pdf" target="_blank">GAP User Guide</a>
          </p>
          <br />
          <p>
            <a href="Download/SRR5486866_1_subseq100K.fastq.gz" download>Sample file 1 for drug resistance prediction</a>
          </p>
          <p>
            <a href="Download/SRR5486866_2_subseq100K.fastq.gz" download>Sample file 2 for drug resistance prediction</a>
          </p>
          <br />

          <p>
            <a href="Download/ETHAMBUTOL_RESISTANT_SRR_CASE.txt" download>Plink sample case file</a>
          </p>

          <p>
            <a href="Download/ETHAMBUTOL_SENSITIVE_SRR_CONTROL.txt" download>Plink sample control file</a>
          </p>
        </div>
        <div className="container">
          <label>TB Samples:</label> &nbsp;&nbsp;&nbsp;&nbsp;SRR24710129,SRR24710279,SRR10430389,SRR13232648 <a href="https://www.ncbi.nlm.nih.gov/sra" target="_blank">SRA</a><br />
          <p className="margin-left-105"> DRR184600,DRR184601,DRR184599,DRR157280,DRR157281 </p>                           
         
          </div>
          <div className="container">
          <label>NTM samples:</label> &nbsp;SRR315266,SRR315352,SRR315372,SRR315386 <a href="https://www.ncbi.nlm.nih.gov/sra" target="_blank">SRA</a><br />
            <p className="margin-left-105">DRR286129 </p>
        </div>
      </div>

    );
  }
}
