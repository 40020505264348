import * as React from "react";
import { Transition, UISref, UISrefActive, useRouter } from "@uirouter/react";
import UserSession from "../../services/UserSession";
import SignIn from './../Header/SignIn';
import { useState } from "react";
import { removeOrcidCode } from "../utils/gapCommon";

type ResistanceState = {
  showlogin:boolean,
  targetstate: string,
  blocking: boolean,
}

export default class Resistance extends React.Component <Transition, ResistanceState> {
  usersession = JSON.parse(sessionStorage.userSession);

  constructor(props: Transition) {
    super(props);
   
    this.state = {
      showlogin: false,
      targetstate:"",
      blocking:false
    };
  }

  handleLoginClose =(islogin:boolean) => {	
		this.setState({showlogin: false})	
		if(islogin && this.state.targetstate !== ""){
      window.location.href = location.origin + "/" + this.state.targetstate;
     }
	}

  loginclick =(state: string) =>{
    this.setState({showlogin: true, targetstate: state})	
  }

  render() {
    removeOrcidCode("resistance", this.props);
    this.usersession = JSON.parse(sessionStorage.userSession);
    return (
      <div className="gap-page-content">
        <SignIn blocking={this.state.blocking} 
                show={this.state.showlogin} 
                targetstate={this.state.targetstate} 
                loginmessage= "Please login to use Resistance feature"
                closeHandle ={(islogin:boolean) => this.handleLoginClose(islogin)} />
          
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className='breadcrumb-item'>
                <UISref to="home"><a>Home</a></UISref>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Resistance</li>
            </ol>
          </nav>
          <h1>Resistance Prediction</h1>
          <p>
            Use genomic data to perform genome wide association studies, search for variants of interest, and compute the distance between samples.
          </p>
          <hr />
          <br />
          {this.usersession.isverified &&
            <div>
              <UISrefActive class="active">
                <UISref to="tbresistancePrediction">
                  <div>
                    <a className="link-primary">TB Resistance Prediction: </a>upload sequencing data (fastq files) for on-the-fly resistance prediction.
                  </div>
                </UISref>
              </UISrefActive>
              <UISrefActive class="active">
                <UISref to="ntmresistancePrediction">
                  <div>
                    <a className="link-primary">NTM Resistance Prediction: </a>upload NTM sequencing data (fastq files) for on-the-fly resistance prediction.
                  </div>
                </UISref>
              </UISrefActive>
            </div>
          }
          {!this.usersession.isverified &&
            <div>
              <div>
                <a className="link-primary" onClick={() => this.loginclick("tbresistancePrediction")}>TB Resistance Prediction: </a>upload sequencing data (fastq files) for on-the-fly resistance prediction.
              </div>
              <div>
                <a className="link-primary" onClick={() => this.loginclick("ntmresistancePrediction")}>NTM Resistance Prediction: </a>upload NTM sequencing data (fastq files) for on-the-fly resistance prediction.
              </div>
            </div>
          }

          <UISrefActive class="active">
            <UISref to="tbportalsdata">
              <div>
                <a className="link-primary">Resistance Prediction and Lineage information (TB Portals Data):</a> upload sequencing data (fastq files) for on-the-fly resistance prediction.
              </div>
            </UISref>
          </UISrefActive>
        </div>
      </div>
   );
  }
}