import React, { useState } from "react";
import { UIView } from "@uirouter/react";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import axios from "axios";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/gap.css';
import API_URL from './services/Enviroment';

const App = () => {
  const [userStatus, setUserStatus] = useState({ login: false });
  const [hasUserSession, sethasUserSession] = useState(false);

  const updateState = (login: boolean) => {
    setUserStatus({ login: login });
  }

  React.useEffect(() => {
    const fetcSession = async () => {
      var usersession = "";
      if (sessionStorage.token == null || sessionStorage.token == "") {
        let url = API_URL + 'login/getnewsession';
        console.log(url);
        const response = await fetch(url);
        const json = await response.json();
        usersession = json;
        sessionStorage.setItem("userSession", JSON.stringify(usersession));
        sessionStorage.setItem("token", json.gap_token);
      }
      else {
        const token = sessionStorage.getItem("token");
        try {
          let url = API_URL + 'admin/getSessions';
          console.log(url);
          const response = await fetch(url, {
            headers: new Headers({
              'Authorization': 'Bearer ' + token
            })
          });
          const json = await response.json();
          usersession = json;
          sessionStorage.setItem("userSession", JSON.stringify(usersession));
          sessionStorage.setItem("token", json.gap_token);
        }
        catch (error) {  //server seesion has been reset
          console.log("Error:" + error + "\nGet new Session");
          let url = API_URL + 'login/getnewsession';
          console.log(url);
          const response = await fetch(url);
          const json = await response.json();
          usersession = json;
          sessionStorage.setItem("userSession", JSON.stringify(usersession));
          sessionStorage.setItem("token", json.gap_token);
        };
      }
    };

    fetcSession()
      .finally(() => {
        sethasUserSession(true);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      {hasUserSession &&
        <div>
          <Header updateState={(login: boolean) => updateState(login)} />
          {/* <br></br>
          <div className="container gap-apps-inner">
              <p className="shutdown_banner">
                Because of a lapse in government funding, the information on this website may not be up to date, transactions submitted via the website may not be processed, and the agency may not be able to respond to inquiries until appropriations are enacted.<br></br>
                The NIH Clinical Center (the research hospital of NIH) is open. For more details about its operating status, please visit <a href="https://cc.nih.gov" target="_blank">cc.nih.gov</a>. <br></br>
                Updates regarding government operating status and resumption of normal operations can be found at <a href="https://OPM.gov" target="_blank">OPM.gov</a>.
              </p>
            </div> */}
          <UIView />
          <Footer />
        </div>
      }
    </div>
  );
};

export default App;