
import React from 'react';
import {useState } from 'react';
import Plot from 'react-plotly.js';

function GWASPlotly(props) {
  var gwasdata = {
    x: [],
    y: [],
    marker: {
      color: 'blue', 
      size: 5, 
      symbol: 'circle'
    }, 
    mode: 'markers', 
    name: '', 
    showlegend: false, 
    text: [], 
    type: 'scatter'
  };

  const updateResultTable = (id) => {
    props.updateResultTable(id);
  }

  if(props.plinkResults !== ''){;
      var PlinkRowData = JSON.parse(props.plinkResults);
      var newPValueList =  PlinkRowData.filter(x=>x.P > 0);
      var miniPValue = Math.min.apply(null, newPValueList.map(function (item) {
        return item.P;
      }));

      for (var i = 0; i < PlinkRowData.length; i++) {
        var rowdata = PlinkRowData[i];
        var x = rowdata.BP;

        var y;
        if (rowdata.P === 0) {
          y = 100 + (-Math.log(miniPValue) / Math.log(10));
        }
        else {
          y = -Math.log(rowdata.P) / Math.log(10);
        }

        gwasdata.x.push(x);
        gwasdata.y.push(y);
      }
  }
  const [data, setData] = useState(gwasdata);
  
  const plotlyClick =(clickdata) => {
    var PlinkRowData = JSON.parse(props.plinkResults);
      var colors = [];
      for (var i = 0; i < clickdata.points.length; i++) {
        var x = clickdata.points[i].x;
        var y = clickdata.points[i].y;
      // var tn = clickdata.points[i].curveNumber;
        var pn = clickdata.points[i].pointNumber;

        for (var k = 0; k < PlinkRowData.length; k++) {
          colors[k] = "blue";
          PlinkRowData[k].selected = false;
          if (x === PlinkRowData[k].BP) {
            var y1;
            if (PlinkRowData[k].P === 0) {
              y1 = 100 + -Math.log(miniPValue) / Math.log(10);
            } else {
              y1 = -Math.log(PlinkRowData[k].P) / Math.log(10);
            }

            if (y === y1) {
              PlinkRowData[k].selected = true;
            }
          }
        }

       colors[pn] = "#C54C82";
       data.marker = {color: colors};
       setData (data);
      }

      updateResultTable(PlinkRowData[pn].id);
  }

  var layout = {
        height: 450, 
        hovermode: 'closest', 
        title: 'Association Results Plot', 
        width: 1000, 
        xaxis1: {        			
          autorange: true, 
          autotick: true, 
          showgrid: false, 
          showticklabels: true, 
          title: 'Genomic Location', 
          zeroline: false
        }, 
        yaxis1: {
          autotick: true,        			
          //range: [0, 20], 
          title: '-log10(PValue)'
        }   
      };

  
    return (
      <div id='plotlydiv'>
      <Plot 
        onClick = {plotlyClick} 
        data={[data]}
        layout={layout}
      />
      </div>
    );
}

export default GWASPlotly;
