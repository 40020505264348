
import React from 'react';
import {useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import PlinkRowData from '../../../domain/PlinkRowData';
import Plotly, {Data, PlotData, Layout, Color, Config}  from "plotly.js";
import BlockUi from 'react-block-ui';
import { Dna } from  'react-loader-spinner';

interface ClusteringDataProps {
    clusteringResult: PlinkRowData[];
    metadata: String;
    metadatatype: string;
    legnedsort: string;
    metadataChanged: boolean;
    pointsSeletced: string[];
    refreshflag: number; 
    selectedPointes(ids:string[]) : void;
 }

const blank_legend = "No data"
const regular_point_color = "rgb(23, 190, 207)";
const no_metadata_point_color = "#D3D3D3";
const colors = ["orangered", "#EEBC1D","darkorchid", "plum", "orchid","#D00060", "#80FF00", "darkred", "#556B2F",
                "pink", "crimson", "red", "maroon","salmon", "chocolate","orange","gold","yellow","olive",
                "lime", "green","aquamarine", "turquoise", "teal", "#00B4D8","blue", "tomato","navy", "indigo", "#993300",
                "#8E4585","magenta","purple","tan","#C71585","#9F00FF", "#800000", "#2F4F4F", "#778899", "brown"];
const clustering3Ddata = {
  x: Array<number>(),
  y: Array<number>(),
  z: Array<number>(),
  mode: "markers",
  type: "scatter3d",
  showlegend: true,
  text: Array<String>(),
  marker: {
    color: Array<Color>(), 
    size: 8,
    line: {
      width: 0
    }
  },
  ids: Array<String>()
} as Data;

const clustering2Ddata = {
  x: Array<number>(),
  y: Array<number>(),
  marker: {
    color: Array<Color>(), 
    size: 8, 
    symbol: 'circle',
    line: {
      width: 0
    }
  }, 
  mode: 'markers', 
  name: '', 
  xaxis: 'x1',
  yaxis: 'y1',
  showlegend: false,
  text: Array<String>(), 
  type: 'scatter',
  ids: Array<String>()
} as Plotly.Data;

const layout2D = {
  height: 700,
  hovermode: 'closest',
  title: 'Clustering 2D',
  width: 600,
  
  xaxis1: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: {
      text: "PC1",
      standoff: 5
    },
    zeroline: true
  },
  yaxis1: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: 'PC2',
    zeroline: true
  },
  xaxis2: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: {
      text: "PC1",
      standoff: 5
    },
    zeroline: true
  },
  yaxis2: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: 'PC3',
    zeroline: true
  },
  xaxis3: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: {
      text: "PC2",
      standoff: 5
    },
    zeroline: true
  },
  yaxis3: {
    autorange: true,
    autotick: true,
    showgrid: true,
    showticklabels: true,
    title: 'PC3',
    zeroline: true
  },
  grid: {
    rows: 3,
    columns: 1,
    ygap: 0.4,
    pattern: 'independent',
    roworder: 'top to bottom'
  },

  dragmode: 'select',
} as unknown as Layout;

const layout3D = {
  autosize: true,
  height: 600,
  scene: {
    aspectratio: {
      x: 1,
      y: 1,
      z: 1,
    },
    camera: {
      center: {
        x: 0,
        y: 0,
        z: 0,
      },
      eye: {
        x: 1.5,
        y: 1.5,
        z: 1.5,
      },
      up: {
        x: 0,
        y: 0,
        z: 10,
      },
    },
    xaxis: {
      type: "linear",
      zeroline: true,
      title: "PC1",
    },
    yaxis: {
      type: "linear",
      zeroline: true,
      title: "PC2",
    },
    zaxis: {
      type: "linear",
      zeroline: true,
      title: "PC3",
    },
  },
  title: "Clustering 3D",
  showlegend: true, 
  width: 600,
} as Layout ;

const clusteringconfig = {
  displayModeBar: true
} as Config;

export default function ClusteringPlotly(props : ClusteringDataProps) {
  const [data3Ds, set3Ddata] = useState(Array<Partial<PlotData>>());
  const [data2Ds, set2Ddata] = useState(Array<Partial<PlotData>>());
  const [plotlyblocking, setBlocking] = useState(false);
  
  var legend_data_PC3D = Array<PlotData>();
  var legend_data_PC2D = Array<PlotData>();

  const buildData =() => {
    if (props.clusteringResult.length > 0) {
      var PlinkRowData = props.clusteringResult;
      var legends = [];
      if (props.metadata != undefined) {

        var legend_frequency_12: { [key: string]: PlotData } = {};
        var legend_frequency_13: { [key: string]: PlotData } = {};
        var legend_frequency_23: { [key: string]: PlotData } = {};
        var legend_frequency_3D: { [key: string]: PlotData } = {};

        var legendString = props.metadata;
        legends = legendString.split("&");
        legends.push(blank_legend);

        for (var l = 0; l < legends.length; l++) {
          var legend = legends[l];
          var color_l = colors[l];
          if(legend == blank_legend){
            color_l = no_metadata_point_color;
          }
          
          var PC12_l = JSON.parse(JSON.stringify(clustering2Ddata));
          var PC13_l = JSON.parse(JSON.stringify(clustering2Ddata));
          var PC23_l = JSON.parse(JSON.stringify(clustering2Ddata));

          PC13_l.xaxis = "x2";
          PC13_l.yaxis = "y2";
          PC23_l.xaxis = "x3";
          PC23_l.yaxis = "y3";

          PC12_l.legendgroup = legend;
          PC13_l.legendgroup = legend;
          PC23_l.legendgroup = legend;

          var PC3D_l = JSON.parse(JSON.stringify(clustering3Ddata));

          PC12_l.name = legend;
          PC12_l.showlegend = true;
          PC3D_l.name = legend;
          PC12_l.marker.color = color_l;
          PC13_l.marker.color = color_l;
          PC23_l.marker.color = color_l;
          PC3D_l.marker.color = color_l;

          for (var i = 0; i < PlinkRowData.length; i++) {
            var rowdata = PlinkRowData[i];
            var metadata = rowdata.metadata;
            var id = rowdata.IID as string;

            var x = rowdata.PC1;
            var y = rowdata.PC2;
            var z = rowdata.PC3;

            if (metadata != undefined) {

              if(metadata.indexOf("lineage4.9", 0) > 0){
                var aa = "";
              }

              var startPos = metadata.indexOf(props.metadatatype) + props.metadatatype.length + 1;
              var endpos = metadata.indexOf('<br>', startPos);
              var legendValue = metadata.substring(startPos, endpos);
              if (endpos < 0) {
                legendValue = metadata.substring(startPos);
              }

              var legendvalueList = [];
              if(legendValue === undefined || legendValue === ""){
                legendvalueList.push(blank_legend);
              }
              else{
                 legendvalueList = legendValue.split(/[,;/&]+/);
              }
              if (legendvalueList.includes(legend)) {
                PC12_l.x.push(x);
                PC12_l.y.push(y);
                PC12_l.ids.push(id);
                PC12_l.text.push(id + "<br>" + metadata);

                PC13_l.x.push(x);
                PC13_l.y.push(z);
                PC13_l.ids.push(id);
                PC13_l.text.push(id + "<br>" + metadata);

                PC23_l.x.push(y);
                PC23_l.y.push(z);
                PC23_l.ids.push(id);
                PC23_l.text.push(id + "<br>" + metadata);

                //3D
                PC3D_l.x.push(x);
                PC3D_l.y.push(y);
                PC3D_l.z.push(z);
                PC3D_l.ids.push(id);
                PC3D_l.text.push(id + "<br>" + metadata);
              }
            }
            else{
              console.log("No metadata:" + id);
            }
          }

         legend_frequency_12[legend] = PC12_l;
         legend_frequency_13[legend] = PC13_l;
         legend_frequency_23[legend] = PC23_l;
         legend_frequency_3D[legend] = PC3D_l;
          
          //legend_data_PC2D.push(PC12_l);
          //legend_data_PC2D.push(PC13_l);
          //legend_data_PC2D.push(PC23_l);

          //legend_data_PC3D.push(PC3D_l);
        }

        //Sort
        if (props.legnedsort === "n"){
          Object.keys(legend_frequency_12).sort().forEach(function(key){
            var legend_frequency_data = legend_frequency_12[key];
            var newlegendName = key + "(" + legend_frequency_data.x.length + ")";

            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC2D.push(legend_frequency_data);
            
            legend_frequency_data = legend_frequency_13[key];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC2D.push(legend_frequency_data);
            
            legend_frequency_data = legend_frequency_23[key];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC2D.push(legend_frequency_data);

            legend_frequency_data = legend_frequency_3D[key];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC3D.push(legend_frequency_data);
          }); 
        }
        else{
          Object.values(legend_frequency_12).sort((a, b) => b.x.length - a.x.length).forEach(function(v){
            var org_legendgroup = v.legendgroup;
            var newlegendName = org_legendgroup + "(" + v.x.length + ")";
            v.legendgroup = newlegendName;
            v.name = newlegendName;
            legend_data_PC2D.push(v);

            var legend_frequency_data = legend_frequency_13[org_legendgroup];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC2D.push(legend_frequency_data);

            legend_frequency_data = legend_frequency_23[org_legendgroup];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC2D.push(legend_frequency_data);

            legend_frequency_data = legend_frequency_3D[org_legendgroup];
            legend_frequency_data.legendgroup = newlegendName;
            legend_frequency_data.name = newlegendName;
            legend_data_PC3D.push(legend_frequency_data);
          });
        }
      }
      else{
        var PC12 = JSON.parse(JSON.stringify(clustering2Ddata));
        var PC13 = JSON.parse(JSON.stringify(clustering2Ddata));
        var PC23 = JSON.parse(JSON.stringify(clustering2Ddata));
        var PC3D = JSON.parse(JSON.stringify(clustering3Ddata));

        for (var i = 0; i < PlinkRowData.length; i++) {
          var rowdata = PlinkRowData[i];
          var metadata = rowdata.metadata;
          var id = rowdata.IID as string;
          var x = rowdata.PC1;
          var y = rowdata.PC2;
          var z = rowdata.PC3;

          PC12.x.push(x);
          PC12.y.push(y);
          PC12.ids.push(id);
          PC12.text.push(id);

          PC13.x.push(x);
          PC13.y.push(z);
          PC13.ids.push(id);
          PC13.text.push(id);

          PC23.x.push(y);
          PC23.y.push(z);
          PC23.ids.push(id);
          PC23.text.push(id);

          //3D
          PC3D.x.push(x);
          PC3D.y.push(y);
          PC3D.z.push(z);
          PC3D.ids.push(id);
          PC3D.text.push(id);
        }
        PC12.marker.color = regular_point_color;
        PC13.marker.color = regular_point_color;
        PC23.marker.color = regular_point_color;
        PC3D.marker.color = regular_point_color;

        PC13.xaxis = "x2";
        PC13.yaxis = "y2";
        PC23.xaxis = "x3";
        PC23.yaxis = "y3";

        PC12.showlegend = false;
        PC13.showlegend = false;
        PC23.showlegend = false;
        PC3D.showlegend = false;

        legend_data_PC2D.push(PC12);
        legend_data_PC2D.push(PC13);
        legend_data_PC2D.push(PC23);

        legend_data_PC3D.push(PC3D);
      }
    }

    if(props.pointsSeletced.length >0){
      findpoint(props.pointsSeletced, legend_data_PC2D);
      findpoint(props.pointsSeletced, legend_data_PC3D);
    }

    set3Ddata(legend_data_PC3D);
    set2Ddata(legend_data_PC2D);
  }

  // const sortLegend =(legends:string[]) =>{
  //   if (props.legnedsort === "n"){
  //     legends.sort();
  //   }
  //   else {

  //   }
  // }

  //3D click issue: the event won't stop
  const plotly3DClick =(clickdata: Plotly.PlotMouseEvent) => {
    setTimeout(() => {
      handle3DClick(clickdata);
    }, 200);
  }

  const handle3DClick =(clickdata: Plotly.PlotMouseEvent) =>{
    for (var i = 0; i < clickdata.points.length; i++) {
      var pn = clickdata.points[i].pointNumber;
      var selectedid = clickdata.points[i].data.ids[pn];

      var selectedIds = [selectedid];
      props.selectedPointes(selectedIds);
    };
  }

  const onPlotly2DClick =(clickdata: Plotly.PlotMouseEvent) => { 
    for (var i = 0; i < clickdata.points.length; i++) {
      var pn = clickdata.points[i].pointNumber;
      var selectedid = clickdata.points[i].data.ids[pn];
      var selectedIds = [selectedid];
      props.selectedPointes(selectedIds);
    }
  }

  const onPlotly2DSelected =(selecteddata: Plotly.PlotSelectionEvent) => {
    if(selecteddata !== undefined && selecteddata.points.length > 0){
      var selectedIds = [];
      for(var i=0; i < selecteddata.points.length; i++){
        var pn = selecteddata.points[i].pointNumber;
        var selectedid = selecteddata.points[i].data.ids[pn];
        selectedIds.push(selectedid);
      }
      props.selectedPointes(selectedIds);
    }
  }

  useEffect(() => {
    buildData();
  }, [props.metadatatype, props.legnedsort, props.refreshflag]);

  const findpoint =(selectedids: String[], data_clone: PlotData[]) => {
    for (var j = 0; j < data_clone.length; j++) {
      var data = data_clone[j];
      var marker = data.marker;
      var groupsizes = Array(data.ids.length).fill(8);

      if(selectedids.length === 0){
        marker.size = groupsizes;
      }
      else{
        for (var k = 0; k < data.ids.length; k++) {
          var id = data.ids[k];
          if (selectedids.includes(id)) {
            groupsizes[k] = 20;
          }
        }
        marker.size = groupsizes;
      }
    }
  }

  return (
    <div id="plotlydiv">
      <BlockUi tag="div" blocking={plotlyblocking} loader={<Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>}>
        <div className='row'>
          <div className='col-md-6'>
            <Plot divId="clustering3D" data={data3Ds} layout={layout3D} onClick={plotly3DClick} config={clusteringconfig} />
          </div>
          <div className='col-md-6'>
            <Plot divId="clustering2D" data={data2Ds} layout={layout2D} config={clusteringconfig} onSelected={onPlotly2DSelected} onClick = {onPlotly2DClick} />
          </div>
        </div>
      </BlockUi>
    </div>
  );
}